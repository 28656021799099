import React, { useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { useTheme, styled } from '@mui/material/styles';
import {
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Select as MuiSelect,
  MenuItem,
  Checkbox,
} from '@mui/material';
import { BasicIconButton } from 'src/components';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const StyledTable = styled(MuiTable)(({ theme }) => ({
  border: '1px solid #E8E8E8',
  borderRadius: '5px',
  borderCollapse: 'separate',
  '& .MuiTableCell-head': {
    fontFamily: 'Comfortaa, Now, Arial',
    fontWeight: 700,
    fontSize: '12px',
    color: '#828282',
    letterSpacing: '0.15px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  '& .MuiTableCell-body': {
    fontFamily: 'Comfortaa, Now, Arial',
    fontWeight: 500,
    fontSize: '12px',
    color: '#333333',
    letterSpacing: '0.15px',
    borderBottom: 'none',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme, onRowClick }) => ({
  cursor: onRowClick ? 'pointer' : 'default',
  '&:hover': onRowClick
    ? {
        backgroundColor: theme.palette.disabled.main,
      }
    : {},
}));
const StyledMuiSelect = styled(MuiSelect)({
  height: '24px',
  fontSize: '12px',
  minWidth: '100px',
});
const StyledMenuItem = styled(MenuItem)({
  fontSize: '12px',
});
const StyledCheckbox = styled(Checkbox)({
  height: '20px',
  width: '20px',
  marginRight: '5px',
  '& svg': {
    height: '20px',
    width: '20px',
  },
});
const StyledTableBodyContainer = styled('div')(({ theme }) => ({
  overflowY: 'auto',
}));
const NoData = styled(Typography)({
  fontFamily: 'Comfortaa, Now, Arial',
  fontWeight: 500,
  fontSize: '12px',
  color: '#333333',
  letterSpacing: '0.15px',
  borderBottom: 'none',
  textAlign: 'center',
});

export const Table = (props) => {
  const theme = useTheme();
  const { columns, data, setData, rowsEditable, rowsDeletable, onRowClick } = props;

  const [tableColumns, setTableColumns] = useState(columns);

  const defaultSortColumn = columns.find((column) => column.defaultSort === true);
  const defaultSortColumnId = defaultSortColumn ? defaultSortColumn.accessor : null;
  const defaultSortDesc = defaultSortColumn ? defaultSortColumn.defaultSortDesc : false;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: defaultSortColumnId ? [{ id: defaultSortColumnId, desc: defaultSortDesc }] : [],
      }, // Initial sorting state (empty)
    },
    useSortBy // Hook for sorting
  );

  const [editIndex, setEditIndex] = useState(-1);

  const handleCellEdit = (rowIndex, field, value) => {
    let newData = [...data];
    newData[rowIndex][field] = value;
    setData(newData);
  };
  const handleRowDelete = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };
  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <StyledTable {...getTableProps()} {...props} stickyHeader>
      <TableHead>
        {headerGroups.map((headerGroup, rowIndex) => (
          <TableRow key={rowIndex} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, cellIndex) => (
              <TableCell
                key={cellIndex}
                {...column.getHeaderProps(column.getSortByToggleProps())} // Add sorting props
                sx={column.id === 'edit' || column.id === 'delete' ? { width: '50px' } : {}}>
                {column.render('Header')}
                {/* Add sorting indicator */}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <span style={{ paddingLeft: '5px' }}>&darr;</span>
                  ) : (
                    <span style={{ paddingLeft: '5px' }}>&uarr;</span>
                  )
                ) : null}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.length ? (
          rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <StyledTableRow
                key={rowIndex}
                {...row.getRowProps()}
                onRowClick={onRowClick}
                onClick={() => handleRowClick(row)}
                sx={rowIndex % 2 !== 0 ? { backgroundColor: '#F8F8F8' } : {}}>
                {row.cells.map((cell, cellIndex) => {
                  if (cell.column.id === 'edit' || cell.column.id === 'delete') {
                    return null;
                  }
                  const editType = cell.column.editType;
                  return (
                    <TableCell
                      key={cellIndex}
                      {...cell.getCellProps()}
                      sx={cell.column.width ? { width: cell.column.width } : {}}>
                      {cell.column.customRender ? (
                        cell.column.customRender(row.original)
                      ) : cell.column.isEditable && editIndex === rowIndex ? (
                        editType === 'text' ? (
                          <input
                            type="text"
                            value={cell.value}
                            onChange={(e) =>
                              handleCellEdit(rowIndex, cell.column.id, e.target.value)
                            }
                          />
                        ) : (
                          <StyledMuiSelect
                            multiple={editType === 'selectMulti'}
                            value={cell.value}
                            renderValue={(selected) =>
                              editType === 'selectMulti' ? selected.join(', ') : selected
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              handleCellEdit(
                                rowIndex,
                                cell.column.id,
                                editType === 'selectMulti' && typeof value === 'string'
                                  ? value.split(',')
                                  : value
                              );
                            }}>
                            {cell.column.options?.map((option) => {
                              return (
                                <StyledMenuItem value={option} key={option}>
                                  {editType === 'selectMulti' ? (
                                    <StyledCheckbox checked={cell.value.indexOf(option) > -1} />
                                  ) : null}
                                  {option}
                                </StyledMenuItem>
                              );
                            })}
                          </StyledMuiSelect>
                        )
                      ) : (
                        cell.render('Cell')
                      )}
                    </TableCell>
                  );
                })}
                {rowsEditable ? (
                  <TableCell
                    key={`cell_${rowIndex}_edit`}
                    class="MuiTableCell-body"
                    sx={{ width: '40px', padding: '8px 0px' }}>
                    <BasicIconButton>
                      {editIndex === rowIndex ? (
                        <CheckRoundedIcon
                          sx={{ fill: theme.palette.green.main }}
                          onClick={() => setEditIndex(-1)}
                        />
                      ) : (
                        <EditNoteRoundedIcon
                          sx={{ fill: theme.palette.green.main }}
                          onClick={() => setEditIndex(rowIndex)}
                        />
                      )}
                    </BasicIconButton>
                  </TableCell>
                ) : null}
                {rowsDeletable ? (
                  <TableCell
                    key={`cell_${rowIndex}_delete`}
                    class=".MuiTableCell-body"
                    sx={{ width: '40px', padding: '8px 0px' }}>
                    <BasicIconButton>
                      <DeleteOutlineRoundedIcon
                        sx={{ fill: theme.palette.error.main }}
                        onClick={() => handleRowDelete(rowIndex)}
                      />
                    </BasicIconButton>
                  </TableCell>
                ) : null}
              </StyledTableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <NoData>No data to display</NoData>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </StyledTable>
  );
};
