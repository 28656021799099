import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { Backdrop, Modal as MuiModal, Fade, Typography, Box } from '@mui/material';
import { BasicIconButton } from 'src/components';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}));
const Container = styled(Stack)(({ theme }) => ({
  height: 'max-content',
  maxHeight: '80dvh',
  minHeight: 'max-content',
  width: 'max-content',
  maxWidth: '80vw',
  minWidth: 'max-content',
  backgroundColor: theme.palette.white.main,
  borderRadius: '20px',
  boxShadow: '0px 4px 20px rgba(51, 51, 51, 0.2)',
  position: 'relative',
  padding: '15px 20px',
}));
const Header = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
}));

export const Modal = (props) => {
  const theme = useTheme();
  const { open, setOpen, label, title, rightContent, closeButton, children } = props;

  const onClose = () => setOpen(false);

  return (
    <StyledMuiModal
      aria-labelledby={label}
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}>
      <Fade in={open}>
        <Container direction="column" {...props}>
          <Header direction="row">
            <Typography variant="h3">{title}</Typography>
            <Stack direction="row">
              {rightContent}
              {closeButton ? (
                <BasicIconButton
                  onClick={onClose}
                  sx={{
                    '& svg': {
                      fill: theme.palette.gold.main,
                    },
                  }}>
                  <CloseRoundedIcon />
                </BasicIconButton>
              ) : null}
            </Stack>
          </Header>
          {children}
        </Container>
      </Fade>
    </StyledMuiModal>
  );
};
