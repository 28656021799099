import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import LocalLibraryRoundedIcon from '@mui/icons-material/LocalLibraryRounded';
import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import MonitorHeartRoundedIcon from '@mui/icons-material/MonitorHeartRounded';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

export const topItems = {
  // dashboard: {
  //   title: 'Dashboard',
  //   Icon: DashboardRoundedIcon,
  // },
  wellnessLogs: {
    title: 'Wellness Logs',
    Icon: FavoriteRoundedIcon,
  },
  biometrics: {
    title: 'Biometrics',
    Icon: MonitorHeartRoundedIcon,
  },
  // insights: {
  //   title: 'INSIGHTS',
  //   Icon: AutoGraphRoundedIcon,
  // },
  nutrition: {
    title: 'Nutrition',
    Icon: RestaurantIcon,
  },
  exercise: {
    title: 'Exercise',
    Icon: DirectionsWalkIcon,
  },
};
/*   // surveys: {
  //   title: 'Surveys',
  //   Icon: FavoriteRoundedIcon,
  // },
  // education: {
  //   title: 'Education',
  //   Icon: LocalLibraryRoundedIcon,
  // }, */
export const bottomItems = [
  { id: 'admin', title: 'Admin', Icon: AdminPanelSettingsRoundedIcon },
  // { id: 'settings', title: 'Settings', Icon: SettingsRoundedIcon },
  // { id: 'support', title: 'Support', Icon: SupportAgentRoundedIcon },
];
