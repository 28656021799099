import React, { useState, useEffect } from 'react';
import { create } from 'zustand';
import {
  createUser,
  generateGroup,
  getAllGroups,
  getAllUsers,
  editUser,
  editGroup,
  deleteUser,
} from 'src/queries/admin';
import { Auth, API, graphqlOperation } from 'aws-amplify';

export const useAdmin = create((set, get) => {
  return {
    groups: [],
    groupsLoading: true,
    users: [],
    usersLoading: true,
    view: 'Groups',
    viewOptions: ['Groups', 'Users'],
    selected: {},
    modalButtonLoading: true,
    setModalButtonLoading: () => {
      return set({ modalButtonLoading: true });
    },
    setSelected: (item) => {
      return set({ selected: item });
    },
    clearSelected: () => {
      return set({ selected: {} });
    },
    setView: (newView) => {
      return set({ view: newView });
    },
    getUsers: () => {
      return getAllUsers((data) => {
        return set({ usersLoading: false, users: data });
      });
    },
    getGroups: () => {
      return getAllGroups((data) => {
        set({ groupsLoading: false, groups: data });
      });
    },
    createUser: async (user) => {
      const { getUsers } = get();
      try {
        set({ usersLoading: true });
        await createUser(user);
        setTimeout(() => {
          getUsers();
        }, 300);
      } catch (e) {
        console.log(e);
      }
    },
    deleteUser: async (user) => {
      const { getUsers } = get();
      try {
        set({ usersLoading: true });
        await deleteUser(user);
        setTimeout(() => {
          getUsers();
        }, 300);
      } catch (e) {
        console.log(e);
      }
    },
    editUser: async (user) => {
      const { getUsers } = get();
      try {
        set({ usersLoading: true });
        await editUser(user);
        setTimeout(() => {
          getUsers();
        }, 300);
      } catch (e) {
        console.log(e);
      }
    },
    editGroup: async (group) => {
      const { getGroups } = get();
      try {
        set({ groupsLoading: true });
        await editGroup(group);
        setTimeout(() => {
          getGroups();
        }, 300);
      } catch (e) {
        console.log(e);
      }
    },
    createGroup: async (inputs) => {
      const { getGroups } = get();
      try {
        set({ groupsLoading: true });
        await generateGroup({
          app: 'RemoteMonitoring',
          name: 'BetaTesters',
          needsConsent: false,
          prodPublic: false,
          devPublic: true,
          demographics: [
            {
              id: 'gender',
              options: [
                'Female',
                'Male',
                'Non-binary',
                'A gender not listed here',
                'Unsure how to describe myself',
                'Prefer not to say',
              ],
              question: 'Which of the following best describes your gender?',
              type: 'radio',
            },
            {
              id: 'race',
              options: [
                'White',
                'Black or African American',
                'Asian',
                'American Indian or Alaska Native',
                'Native Hawaiian or Pacific Islander',
                'Hispanic',
                'Other',
                'Prefer not to say',
              ],
              question: 'Which race and/or ethnicity do you identify with?',
              type: 'radio',
            },
          ],
          education: [],
          emails: [],
          fullDescription:
            'Join this study to beta test the app & contribute data to our beta testing database.',
          medications: [
            'Hydroxyurea',
            'Folic Acid',
            'L-Glutamine',
            'Voxelotor',
            'Crizanlizumab',
            'Ibuprofen',
            'Tylenol',
            'Vitamin D',
          ],
          numUsers: 38,
          shortDescription: 'Beta Testing',
          symptoms: [
            'pain',
            'tired',
            'itching',
            'trouble pooping',
            'headache',
            'nausea',
            'priapism',
          ],
          usernameFormat: '%03d',
        });
        setTimeout(() => {
          getGroups();
        }, 300);
      } catch (e) {
        console.log(e);
      }
    },
  };
});
