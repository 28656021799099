import React from 'react';
import { styled } from '@mui/material/styles';
import { FormControl as MuiFormControl } from '@mui/material';

const StyledFormControl = styled(MuiFormControl)(({ theme }) => ({
  '&.MuiFormControl-root': {
    gap: theme.spacing(3),
    padding: '10px',
  },
}));

export const FormControl = (props) => {
  return <StyledFormControl {...props} />;
};
