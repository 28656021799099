import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { BrowserView, MobileView } from 'react-device-detect';
import { Drawer } from '@mui/material';
import { useWindowSize, useDashStore, useAuth } from 'src/hooks';
import { ExpandedSidebar } from './ExpandedSidebar';
import { CollapsedSidebar } from './CollapsedSidebar';
import { SidebarItems } from './SidebarItems';

const expandedDrawerWidth = 280;
const collapsedDrawerWidth = 98;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  background: 'linear-gradient(177.8deg, #276445 0.95%, #4C936F 118.2%)',
  position: 'fixed',
  left: 0,
  height: '100dvh',
  borderRadius: '0px 15px 15px 0px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  '& .MuiPaper-root': {
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
  },
}));

export const Sidebar = ({ open, setOpen }) => {
  const { width } = useWindowSize();
  const { getGroups } = useDashStore();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      getGroups(user);
    }
  }, [user]);

  const toggleSidebar = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (width <= 750 && open) {
      setOpen(false);
    }
  }, [width]);

  const MixedWidthDrawer = styled(StyledDrawer)({
    width: open ? expandedDrawerWidth : collapsedDrawerWidth,
    '& .MuiPaper-root': {
      width: open ? expandedDrawerWidth : collapsedDrawerWidth,
    },
  });

  const expandedDrawer = (
    <ExpandedSidebar toggleSidebar={toggleSidebar}>
      <SidebarItems collapsed={false} />
    </ExpandedSidebar>
  );

  const collapsedDrawer = (
    <CollapsedSidebar toggleSidebar={toggleSidebar}>
      <SidebarItems collapsed={true} />
    </CollapsedSidebar>
  );

  const mobileView = (
    <StyledDrawer
      variant="temporary"
      open={open}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: expandedDrawerWidth },
      }}>
      {expandedDrawer}
    </StyledDrawer>
  );

  return (
    <React.Fragment>
      {/* WEB */}
      {/* <BrowserView>
        <MixedWidthDrawer variant="persistent" anchor="left" open={true}>
          {open ? expandedDrawer : collapsedDrawer}
        </MixedWidthDrawer>
      </BrowserView> */}

      {/* MOBILE */}
      {/* <MobileView>{mobileView}</MobileView> */}
      <MixedWidthDrawer variant="persistent" anchor="left" open={true}>
        {open ? expandedDrawer : collapsedDrawer}
      </MixedWidthDrawer>
    </React.Fragment>
  );
};
