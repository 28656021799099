import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { useDashStore, useInsights } from 'src/hooks';
import { Header, Select, Alert } from 'src/components';
import { NetworkChart } from './NetworkChart';
import { CentralityChart } from './CentralityChart';
import { spaceSeparated } from './helpers';

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.offWhite.main,
  height: '100dvh',
  display: 'flex',
}));

const chartOptions = [{}];

export const Insights = (props) => {
  const { showAlert, dismissAlert, group, appUsers, userOptions } = useDashStore();
  const { getInsightsForGroup, runInsights, chartOptions, selectedChart } = useInsights();

  useEffect(() => {
    getInsightsForGroup(group.name);
  }, [group]);

  return (
    <Container {...props}>
      <Header title="INSIGHTS">
        <Select
          id="chart-select"
          options={chartOptions}
          value={useInsights().selectedChart}
          setValue={useInsights().setSelectedChart}
          customRender={spaceSeparated}
          sx={{ minWidth: '200px' }}
        />
      </Header>
      {selectedChart === 'NetworkChart' ? <NetworkChart /> : <CentralityChart />}
    </Container>
  );
};
