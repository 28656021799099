/* eslint-disable no-undef */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Tooltip } from '@mui/material';
import { Stack } from '@mui/joy';
import { Chip, LoadingSpinner } from 'src/components';

const StyledTypography = styled(Typography)({
  fontFamily: 'Comfortaa, Now, Arial',
  fontWeight: 500,
  fontSize: '12px',
  color: '#333333',
  letterSpacing: '0.15px',
  borderBottom: 'none',
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  padding: '10px 0px',
});

export const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 100px)',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: 3,
}));

export const Loading = () => {
  return (
    <Container>
      <LoadingSpinner sx={{ height: '5vmin !important', width: '5vmin !important' }} />
      <Typography variant="h3" sx={{ color: '#333333' }}>
        Loading Logs
      </Typography>
    </Container>
  );
};

// format ISO date to "<Month> <Day>"
// returns "Today", "Yesterday", or "<NumDaysAgo> Days Ago" if within past week
function renderDateString(params) {
  const { row } = params;
  const dateString = row.dateAndTimeSubmitted;
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const timeDiff = today.getTime() - date.getTime();
  const dayDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  const localeDateString = date.toLocaleDateString('en-US', options);

  if (date.toDateString() === today.toDateString()) {
    return <Tooltip title={localeDateString}>Today</Tooltip>;
  } else if (date.toDateString() === yesterday.toDateString()) {
    return <Tooltip title={localeDateString}>Yesterday</Tooltip>;
  } else if (dayDiff < 7) {
    return <Tooltip title={localeDateString}>{dayDiff} Days Ago</Tooltip>;
  } else {
    return localeDateString;
  }
}

// based on window width, get a width to use for symptom & medications columns
// allows those columns to get larger/smaller based on screen size
const getDynamicCellWidth = (windowWidth) => {
  return windowWidth > 1400
    ? 350
    : windowWidth > 1300
    ? 300
    : windowWidth > 1200
    ? 250
    : windowWidth > 1050
    ? 200
    : windowWidth > 950
    ? 150
    : 100;
};

// function to get table columns for single user view
export const getSingleUserColumns = () => {
  return [
    {
      headerName: 'Log Date',
      field: 'dateAndTimeSubmitted',
      defaultSort: true,
      defaultSortDesc: true,
      renderCell: renderDateString,
      minWidth: 130,
      flex: 1,
    },
    {
      headerName: 'Type',
      field: 'exerciseType',
      minWidth: 130,
      flex: 1,
    },
    {
      headerName: 'Perceived Exertion',
      field: 'exertion',
      minWidth: 150,
      flex: 2,
    },
    {
      headerName: 'Resistance',
      field: 'resistance',
      minWidth: 130,
      flex: 1,
    },
    {
      headerName: 'Duration',
      field: 'duration',
      minWidth: 130,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <StyledTypography>
            {row.duration ? `${row.duration} ${row.durationUnit}` : ''}
          </StyledTypography>
        );
      },
    },
    {
      headerName: 'Distance',
      field: 'distance',
      minWidth: 130,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <StyledTypography>
            {row.distance ? `${row.distance} ${row.distanceUnit}` : ''}
          </StyledTypography>
        );
      },
    },
    {
      headerName: 'Total # Reps',
      field: 'numSets',
      minWidth: 130,
      flex: 1,
      renderCell: ({ row }) => {
        // calculate # reps
        let repsPerSet = JSON.parse(JSON.parse(row.repsPerSet));
        return (
          <StyledTypography>
            {repsPerSet
              ? Object.values(repsPerSet).reduce((prev, curr) => {
                  return prev + curr;
                }, 0)
              : ''}
          </StyledTypography>
        );
      },
    },
    // {
    //   headerName: 'Status',
    //   field: 'status',
    //   renderCell: (params) => {
    //     const { row } = params;
    //     return (
    //       <Chip
    //         variant={
    //           row.status === 'logging'
    //             ? 'neutral'
    //             : row.status === 'no symptoms'
    //             ? 'success'
    //             : 'error'
    //         }
    //         label={row.status}
    //       />
    //     );
    //   },
    //   minWidth: 110,
    //   flex: 1,
    // },
    // {
    //   headerName: 'Feeling',
    //   field: 'feeling',
    //   renderCell: (params) => {
    //     return (
    //       <div
    //         style={{
    //           display: 'flex',
    //           margin: '-15px -10px -15px 0px',
    //         }}>
    //         {feelingsMap[params.row.feeling]}
    //       </div>
    //     );
    //   },
    //   minWidth: 100,
    //   flex: 1,
    // },
    // {
    //   headerName: 'Symptoms',
    //   field: 'symptoms',
    //   renderCell: (params) => {
    //     let symptoms = JSON.parse(params.row.symptoms);
    //     return (
    //       <StyledTypography>
    //         {Object.keys(symptoms)
    //           .map((symptom) => {
    //             return `${symptom
    //               .split(/(?=[A-Z])/)
    //               .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    //               .join(' ')} (${symptoms[symptom].intensity})`;
    //           })
    //           .join(', ')}
    //       </StyledTypography>
    //     );
    //   },
    //   minWidth: 120,
    //   flex: 3,
    // },
    // {
    //   headerName: 'Medications',
    //   field: 'medications',
    //   renderCell: (params) => {
    //     return <StyledTypography>{params.row.medications.join(', ')}</StyledTypography>;
    //   },
    //   minWidth: 120,
    //   flex: 3,
    // },
  ];
};

// function to get table columns for all users view
export const getAllUserColumns = () => {
  let singleUserColumns = getSingleUserColumns();
  return [
    {
      headerName: 'ID',
      field: 'username',
      minWidth: 130,
      flex: 1,
    },
    ...singleUserColumns,
  ];
};

// columns to include in csv
const csvColumns = [
  'username',
  'exerciseType',
  'exertion',
  'resistance',
  'duration',
  'durationUnit',
  'distance',
  'distanceUnit',
  'numSets',
];

export const downloadLogCSV = (data, filename) => {
  // create header string
  const headerKeys = csvColumns.map((column) => column);
  const header = headerKeys.join(',');

  // create csv string
  const csvContent = [
    header,
    ...data.map((item) =>
      headerKeys
        .map((key) => {
          let value = item[key];
          return Array.isArray(value) ? value.join(';') : value;
        })
        .join(',')
    ),
  ].join('\n');

  // download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
