import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import {
  useDashStore,
  useWellnessLogs,
  // useEducation
} from 'src/hooks';
import { Header, Select, Alert } from 'src/components';
import { WellnessLogsTable } from './WellnessLogsTable';

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.offWhite.main,
  height: '100dvh',
  display: 'flex',
}));

export const WellnessLogs = (props) => {
  // const [didCreateEducation, setDidCreateEducation] = useState(false);
  const { selectedUser, setSelectedUser, userOptions, appUsers, showAlert, groups, dismissAlert } =
    useDashStore();

  const { allLogs, mostRecentLogs, getWellnessLogsForUsers, getMostRecentLogsByUser } =
    useWellnessLogs();

  // const { createEducation } = useEducation();

  useEffect(() => {
    if (userOptions.length > 1) {
      let options = userOptions.slice(1, userOptions.length);
      getWellnessLogsForUsers(options);
    }
    // if (!didCreateEducation) {
    //   console.log('created education');
    //   createEducation();
    // }
  }, [userOptions]);

  const setNewUser = (val) => {
    const newUserObject = userOptions.find((i) => i.username === val);
    setSelectedUser(newUserObject);
  };

  return (
    <Container {...props}>
      <Header title="Wellness Logs">
        <Select
          id="user-select"
          options={userOptions.map((i) => i.username)}
          value={selectedUser.username}
          setValue={setNewUser}
          sx={{ minWidth: '200px' }}
        />
      </Header>
      <WellnessLogsTable />
    </Container>
  );
};
