import React, { useState, useEffect, memo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import {
  BarChart,
  Customized,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Rectangle,
  Bar,
  ResponsiveContainer,
  Brush,
} from 'recharts';
import { Select, Header, IconButton } from 'src/components';
import { Loading } from '../../helpers';
import {
  getFirstDateInData,
  getLastDateInData,
  getDaysBetweenDates,
  ChartContainer,
  NoData,
  TooltipContainer,
  dateTickFormat,
} from './chartHelpers';

const groupStepDataByDay = (data, device, start, end) => {
  const filteredData = data.filter((i) => i.deviceProductType.includes(device));

  const dateMap = new Map();
  // group items by day
  for (const item of filteredData) {
    const date = item.startDate.split('T')[0];
    if (!dateMap.has(date)) {
      dateMap.set(date, []);
    }
    dateMap.get(date).push(item.value);
  }

  // Loop through each day from start to end
  return Array.from(dateMap)
    .map((item) => {
      return {
        date: new Date(item[0].replace(/-/g, '/')).getTime(),
        sum: item[1].reduce((a, b) => {
          return a + parseInt(b);
        }, 0),
      };
    })
    .sort((a, b) => {
      return a.date - b.date;
    });
};

const groupStepDataByHours = (data, device, start, end, numHours) => {
  const newData = [];
  let currentDateTime = new Date(start).getTime();
  const intervalMilliseconds = numHours * 60 * 60 * 1000;
  const filteredData = data.filter((i) => i.deviceProductType.includes(device));

  while (currentDateTime <= end.getTime()) {
    const intervalStart = currentDateTime;
    const intervalEnd = currentDateTime + intervalMilliseconds;
    let sum = 0;
    let count = 0;
    for (let i = 0; i < filteredData.length; i++) {
      const item = filteredData[i];
      const itemDateTime = new Date(item.startDate).getTime();
      if (itemDateTime >= intervalStart && itemDateTime < intervalEnd) {
        const value = parseInt(item.value);
        sum += value;
        count++;
      }
    }
    if (count > 0) {
      newData.push({ date: currentDateTime, sum });
    }
    currentDateTime += intervalMilliseconds;
  }
  return newData.sort((a, b) => {
    return a.date - b.date;
  });
};

const granularityOptions = {
  '3 Hours': (data, device, start, end) => groupStepDataByHours(data, device, start, end, 3),
  '6 Hours': (data, device, start, end) => groupStepDataByHours(data, device, start, end, 6),
  '12 Hours': (data, device, start, end) => groupStepDataByHours(data, device, start, end, 12),
  '24 Hours': (data, device, start, end) => groupStepDataByDay(data, device, start, end),
};

const getGroupedData = (data, device, granularityOptions, granularity) => {
  const start = getFirstDateInData(data);
  const end = getLastDateInData(data);
  let view = granularity || '24 Hours';
  if (!granularity) {
    const difference = getDaysBetweenDates(start, end);
    if (difference <= 7) {
      view = '3 Hours';
    } else if (difference <= 30) {
      view = '6 Hours';
    } else if (difference <= 90) {
      view = '12 Hours';
    }
  }
  const avgData = granularityOptions[view](data, device, start, end);

  return [view, avgData];
};

export const StepCountChart = ({ data }) => {
  const theme = useTheme();

  const defaults = getGroupedData(data, 'iPhone', granularityOptions);

  const [granularity, setGranularity] = useState(defaults[0]);
  const [groupedData, setGroupedData] = useState(defaults[1]);
  const [loading, setLoading] = useState(false);
  const [device, setDevice] = useState('iPhone');

  useEffect(() => {
    const newData = getGroupedData(data, device, granularityOptions);
    setGranularity(newData[0]);
    setGroupedData(newData[1]);
  }, [data]);

  useEffect(() => {
    const newData = getGroupedData(data, device, granularityOptions, granularity);
    setGroupedData(newData[1]);
    setLoading(false);
  }, [granularity, device]);

  const StepTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = new Date(label);
      let dateLabel = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      if (granularity !== '24 Hours') {
        dateLabel += ` ${String(date.getHours()).padStart(2, '0')}:${String(
          date.getMinutes()
        ).padStart(2, '0')}`;
      }
      return (
        <TooltipContainer>
          <Typography variant="instructions">{dateLabel}</Typography>
          <Typography variant="paragraph">{`${payload[0].value} steps`}</Typography>
        </TooltipContainer>
      );
    }
    return null;
  };

  return (
    <ChartContainer>
      <Header title="Step Count" level={3} style={{ marginBottom: '20px' }}>
        <Select
          id="device-type-select"
          label="Device Type"
          options={['iPhone', 'Watch']}
          value={device}
          setValue={(val) => {
            setLoading(true);
            setTimeout(() => {
              setDevice(val);
            }, 500);
          }}
          sx={{ minWidth: '200px', maxHeight: '40px' }}
          stackSX={{ marginTop: '-10px' }}
        />
        <Select
          id="granularity-select"
          label="Granularity"
          options={Object.keys(granularityOptions)}
          value={granularity}
          setValue={(val) => {
            setLoading(true);
            setTimeout(() => {
              setGranularity(val);
            }, 500);
          }}
          sx={{ minWidth: '200px', maxHeight: '40px' }}
          stackSX={{ marginTop: '-10px' }}
        />
      </Header>
      {loading ? (
        <Loading text={'Chart'} />
      ) : groupedData.length ? (
        <ResponsiveContainer width="95%" height="90%">
          <BarChart data={groupedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              domain={['auto', 'auto']}
              type="number"
              scale="time"
              tickFormatter={dateTickFormat}
              tickMargin={8}
            />
            <YAxis type="number" label={'# steps'} angle={-45} />
            <Tooltip content={StepTooltip} cursor={{ fill: theme.palette.offWhite.main }} />
            <Brush
              dataKey="date"
              height={30}
              stroke={theme.palette.green.main}
              tickFormatter={dateTickFormat}
            />
            <Bar dataKey="sum" fill={theme.palette.gold.main} radius={5} maxBarSize={10} />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <NoData />
      )}
    </ChartContainer>
  );
};
