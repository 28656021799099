import React, { useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import { TextField, Button, Checkbox } from 'src/components';
import { FileUploader } from '../../FileUploader';

const Container = styled(Stack)({
  margin: '15px 0px',
  gap: 3,
  minWidth: '400px',
});
const Row = styled(Stack)({
  flexDirection: 'row',
  gap: 3,
});
const Separated = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ConsentFormConfig = (props) => {
  const { isNew, form, file, setFile, setPage } = props;
  const { getValues, control, setValue } = form;

  const [includeDate, setIncludeDate] = useState(getValues().includeDate || false);
  const [errorMessage, setErrorMessage] = useState('');

  const theme = useTheme();

  const onSubmit = () => {
    let shouldContinue = true;
    const values = getValues();

    if (
      file &&
      (!values.signaturePage ||
        !values.x ||
        !values.x ||
        (values.includeDate && (!values.dateX || !values.dateY)))
    ) {
      setErrorMessage('Oops! Please fill out all required fields.');
      shouldContinue = false;
    }

    if (shouldContinue) {
      setErrorMessage('');
      setPage(0);
    }
  };

  return (
    <Container direction="column" {...props}>
      <Separated>
        <Typography variant="h4">Consent Form Configuration</Typography>

        <Stack direction="row">
          <Controller
            name="includeDate"
            control={control}
            render={({ field }) => (
              <Checkbox
                id="include-date-input"
                label="include date stamp"
                {...field}
                onChange={(val) => {
                  setIncludeDate(val.target.checked);
                  setValue('includeDate', val.target.checked);
                }}
              />
            )}
          />
        </Stack>
      </Separated>
      <Row>
        <FileUploader file={file} setFile={setFile} />
        {file ? (
          <Stack direction="column" sx={{ gap: '5px' }}>
            <Controller
              name="signaturePage"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="signature-page-input"
                  label="Signature Page"
                  size="small"
                  required
                  {...field}
                />
              )}
            />
            <Typography variant="h4" style={{ marginTop: '15px' }}>
              Signature Coordinates
            </Typography>
            <Row>
              <Controller
                name="x"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField id="x-input" label="X" size="small" required {...field} />
                )}
              />
              <Controller
                name="y"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField id="y-input" label="Y" size="small" required {...field} />
                )}
              />
            </Row>
            {includeDate ? (
              <Typography variant="h4" style={{ marginTop: '15px' }}>
                Date Coordinates
              </Typography>
            ) : null}
            {includeDate ? (
              <Row>
                <Controller
                  name="dateX"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField id="date-x-input" label="X" size="small" required {...field} />
                  )}
                />
                <Controller
                  name="dateY"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField id="date-y-input" label="Y" size="small" required {...field} />
                  )}
                />
              </Row>
            ) : null}
          </Stack>
        ) : null}
      </Row>
      <Separated>
        <Typography
          variant="paragraph"
          color={theme.palette.error.main}
          sx={{ wordWrap: 'break-word', maxWidth: 300 }}>
          {errorMessage}
        </Typography>
        <Button
          sx={{
            height: '40px !important',
            width: '200px',
            fontFamily: 'Comfortaa, Now, Arial',
          }}
          onClick={onSubmit}>
          Back to Group
        </Button>
      </Separated>
    </Container>
  );
};
