import { API, graphqlOperation } from 'aws-amplify';
import { apiQuery, apiBatchQuery } from './base';

export const listMeals = /* GraphQL */ `
  query ListMeals($filter: ModelMealFilterInput, $limit: Int, $nextToken: String) {
    listMeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateAndTimeSubmitted
        mealTime
        appUserID
        name
        foods {
          fdcid
          count
          portion
          name
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const fetchFoodsByFDCIDs = async (fdcIDs) => {
  const apiKey = 'sYD3ZThnDFptUtCoasL2AJLuuFCPAYOKlhcVV6nL';
  const baseURL = 'https://api.nal.usda.gov/fdc/v1/foods/';

  const foodsData = [];

  try {
    const response = await fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        api_key: apiKey,
      },
      body: JSON.stringify({ fdcIds: fdcIDs }),
    });

    if (response.ok) {
      const responseData = await response.json();
      foodsData.push(...responseData.foods);
    } else {
      console.error(`Failed to fetch data for FDC IDs. Status Code: ${response.status}`);
    }
  } catch (error) {
    console.error(`Error fetching data for FDC IDs: ${error.message}`);
  }

  return foodsData;
};

const mapMeals = (logs, appUsers, resolver) => {
  const meals = [];
  logs.forEach((log) => {
    const { appUserID, id, ...logNoID } = log;
    const appUser = appUsers.find((user) => user.id === appUserID);
    if (appUser) {
      const { username } = appUser;

      const fullLog = {
        username: username,
        ...logNoID,
      };
      meals.push(fullLog);
    }
  });
  resolver(meals);
};

export const getNutritionLogsForUsers = async (appUsers, setNutritionLogs) => {
  const appUserFilters = appUsers.map((user) => {
    return { appUserID: { eq: user.id } };
  });
  let nutritionLogs = [];

  // grab logs
  try {
    await apiBatchQuery(listMeals, { filter: { or: appUserFilters } }, 'listMeals', (data) => {
      nutritionLogs = data;
      // get fdc IDs
      let fdcids = [];
      nutritionLogs.forEach((log) => {
        log.foods?.forEach((food) => {
          console.log(food);
          fdcids.push(food.fdcid);
        });
      });

      if (fdcids.length) {
        // fetch from FDC API
        fetchFoodsByFDCIDs(fdcids)
          .then((foodsData) => {
            foodsData.forEach((foodData) => {
              console.log(foodData);
              console.log(`Food Name: ${foodData.description}`);
              console.log(`Ingredients: ${foodData.ingredients || 'N/A'}`);
              console.log(`Nutrients: ${foodData.foodNutrients || 'N/A'}`);
              console.log('--------------------');
            });
            // mapMeals(nutritionLogs, appUsers, setNutritionLogs);
          })
          .catch((error) => console.error(`Error: ${error.message}`));
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const getNutritionLogsForUser = (appUserID, setNutritionLogs) => {
  return apiQuery(listMeals, { filter: { appUserID: { eq: appUserID } } }, setNutritionLogs);
};
