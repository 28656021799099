import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { IconButton, Tooltip } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  // '&.MuiIconButton-root': {
  //   height: '20px',
  //   borderRadius: '9px',
  //   fontSize: '10px',
  // },
}));

export const Info = (props) => {
  const theme = useTheme();
  const { tooltip } = props;

  return (
    <Tooltip title={tooltip}>
      <StyledIconButton>
        <InfoRoundedIcon />
      </StyledIconButton>
    </Tooltip>
  );
};
