import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { useDashStore, useNutrition } from 'src/hooks';
import { Header, Select, Alert } from 'src/components';
import { NutritionTable } from './NutritionTable';

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.offWhite.main,
  height: '100dvh',
  display: 'flex',
}));

export const Nutrition = (props) => {
  const { selectedUser, setSelectedUser, userOptions } = useDashStore();

  const { mostRecentLogs, getNutritionLogsForUsers, setLoading } = useNutrition();

  useEffect(() => {
    if (userOptions.length > 1) {
      let options = userOptions.slice(1, userOptions.length);
      getNutritionLogsForUsers(options);
    } else {
      setLoading(false);
    }
  }, [userOptions]);

  const setNewUser = (val) => {
    const newUserObject = userOptions.find((i) => i.username === val);
    setSelectedUser(newUserObject);
  };

  return (
    <Container {...props}>
      <Header title="Nutrition Logs">
        <Select
          id="user-select"
          options={userOptions.map((i) => i.username)}
          value={selectedUser.username}
          setValue={setNewUser}
          sx={{ minWidth: '200px' }}
        />
      </Header>
      <NutritionTable />
    </Container>
  );
};
