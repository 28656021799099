import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Stack } from '@mui/joy';
import { Header, Select, Table, Card, IconButton, Alert, LoadingSpinner } from 'src/components';
import { userColumns, groupColumns } from './columns';
import { UserModal, GroupModal } from './components';
import { useAdmin } from 'src/hooks';
import { formatUsers, formatGroups } from './helpers';

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.offWhite.main,
  height: '100dvh',
  display: 'flex',
}));
const LoadingContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 100px)',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: 3,
}));
const Loading = ({ text }) => {
  return (
    <LoadingContainer>
      <LoadingSpinner sx={{ height: '5vmin !important', width: '5vmin !important' }} />
      <Typography variant="h3" sx={{ color: '#333333' }}>
        Loading {text}
      </Typography>
    </LoadingContainer>
  );
};

export const Admin = (props) => {
  const {
    view,
    viewOptions,
    setView,
    users,
    groups,
    groupsLoading,
    usersLoading,
    getGroups,
    getUsers,
    setSelected,
    clearSelected,
    // createGroup,
  } = useAdmin();

  const [modalsOpen, setModalsOpen] = useState(new Array(2).fill(false));

  const setModalOpen = (index, open) => {
    let copy = [...modalsOpen];
    copy[index] = open;
    setModalsOpen(copy);
    if (!open) {
      setTimeout(() => {
        clearSelected();
      }, 300);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getGroups();
      getUsers();
    }, 500);
  }, []);

  const data = view === 'Users' ? formatUsers(users, groups) : formatGroups(users, groups);
  const isLoading = usersLoading || groupsLoading;

  const onRowClick = (row) => {
    if (view === 'Users') {
      setSelected(row.original);
      setModalOpen(view === 'Users' ? 0 : 1, true);
    }
  };

  return (
    <Container {...props}>
      <Header title="Admin">
        <Select
          id="view-select"
          options={viewOptions}
          value={view}
          setValue={setView}
          sx={{ minWidth: '200px' }}
        />
      </Header>
      <Alert sx={{ marginTop: '20px' }} severity="warning" title="Under Construction">
        This page teeeeechnically works now, but changes to/creation of
        <b> groups</b> will not be reflected on the iOS side yet :)
      </Alert>
      <Card
        sx={{ height: '100%', marginTop: '20px' }}
        title={view === 'Users' ? 'Dashboard Users' : 'Groups'}
        headerContent={
          view === 'Users' ? (
            <IconButton
              icon="add"
              tooltip={view === 'Users' ? 'Add New User' : 'Add New Group'}
              onClick={() => {
                // createGroup();
                setModalOpen(view === 'Users' ? 0 : 1, true);
              }}
            />
          ) : null
        }>
        {isLoading ? (
          <Loading text={view} />
        ) : (
          <Stack sx={{ marginTop: '20px', overflowY: 'auto' }}>
            <Table
              columns={view === 'Users' ? userColumns : groupColumns}
              data={data}
              onRowClick={onRowClick}
            />
          </Stack>
        )}
      </Card>
      <UserModal open={modalsOpen[0]} setOpen={(open) => setModalOpen(0, open)} />
      <GroupModal open={modalsOpen[1]} setOpen={(open) => setModalOpen(1, open)} />
    </Container>
  );
};
