import React, { useState, useEffect, memo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  ResponsiveContainer,
  Brush,
} from 'recharts';
import { Header } from 'src/components';
import {
  ChartContainer,
  NoData,
  TooltipContainer,
  dateTickFormat,
  formatBasicData,
} from './chartHelpers';

const HRTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const date = new Date(label);
    const dateLabel = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${String(
      date.getHours()
    ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    return (
      <TooltipContainer>
        <Typography variant="instructions">{dateLabel}</Typography>
        <Typography variant="paragraph">{`Resting Heart Rate: ${payload[0].value} bpm`}</Typography>
      </TooltipContainer>
    );
  }
  return null;
};

export const RestingHRChart = ({ data }) => {
  const theme = useTheme();

  const [chartData, setChartData] = useState(formatBasicData(data));

  useEffect(() => {
    setChartData(formatBasicData(data));
  }, [data]);

  return (
    <ChartContainer>
      <Header title="Resting Heart Rate" level={3} style={{ marginBottom: '20px' }}></Header>
      {data.length ? (
        <ResponsiveContainer width="95%" height="90%">
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              domain={['auto', 'auto']}
              type="number"
              scale="time"
              tickFormatter={dateTickFormat}
              tickMargin={8}
            />
            <YAxis type="number" label={'bpm'} angle={-45} />
            <Tooltip content={HRTooltip} />
            <Brush
              dataKey="date"
              height={30}
              stroke={theme.palette.green.main}
              tickFormatter={dateTickFormat}
            />
            <Line
              type="monotone"
              dataKey="value"
              stroke={theme.palette.green.main}
              strokeWidth={2}
              dot={{ stroke: theme.palette.green.main, strokeWidth: 5, r: 2 }}
              activeDot={{ stroke: theme.palette.green.main, strokeWidth: 5, r: 4 }}
              name="Resting Heart Rate"
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <NoData />
      )}
    </ChartContainer>
  );
};
