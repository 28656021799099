import { API, graphqlOperation } from 'aws-amplify';
import { getFromStorage } from './base';

const mapBiometrics = (biometrics, appUsers, resolver, csvData) => {
  const bios = [];
  biometrics.forEach((bio) => {
    const { appUserID, id, ...bioNoID } = bio;
    const appUser = appUsers.find((user) => user.id === appUserID);
    if (appUser) {
      const { username } = appUser;

      const fullLog = {
        username: username,
        ...bioNoID,
      };
      bios.push(fullLog);
    }
  });
  resolver(bios, csvData);
};

export const getBiometricData = async (group, biometric, appUsers, resolver) => {
  await getFromStorage(`${group}/${biometric}.csv`, 'csv', (data, csvData) => {
    mapBiometrics(data, appUsers, resolver, csvData);
  });
};

// const hrQuery = `query GetHeartRateByUser($hrFilters: [ModelHeartRateFilterInput!], $limit: Int, $nextToken: String) {
//   listHeartRates(filter: { or: $hrFilters }, limit: $limit, nextToken: $nextToken) {
//     items {
//       id
//       appUserID
//       startDate
//       endDate
//       sourceID
//       eventID
//       value
//       unit
//       sampleContext
//       createdAt
//     }
//     nextToken
//   }
// }`;

// export const getHeartRateForUser = async (appUser, setHeartRates) => {
//   // const weekAgoTimestamp = moment().subtract(2, 'week').toISOString();
//   console.log(appUser.id, weekAgoTimestamp);

//   return apiBatchQuery(
//     listHeartRates,
//     {
//       filter: {
//         createdAt: { ge: weekAgoTimestamp },
//         appUserID: { eq: appUser.id },
//       },
//     },
//     'listHeartRates',
//     setHeartRates
//   );
// };
