import React, { useState, useEffect } from 'react';
import { create } from 'zustand';
import { getWellnessLogsForUsers } from 'src/queries/wellnessLogs';

export const useWellnessLogs = create((set, get) => {
  return {
    logs: [],
    loadingLogs: true,
    setLogs: (newLogs) => {
      return set({ logs: newLogs });
    },
    mostRecentLogs: [],
    setMostRecentLogs: (newLogs) => {
      return set({ mostRecentLogs: newLogs });
    },
    getWellnessLogsForUsers: (appUsers) => {
      const { getMostRecentLogsByUser } = get();
      set({ loadingLogs: true });
      getWellnessLogsForUsers(appUsers, (logs) => {
        getMostRecentLogsByUser(appUsers, logs);
        return set({ loadingLogs: false, logs });
      });
    },
    getMostRecentLogsByUser: (appUsers, logs) => {
      const recentLogs = appUsers.map((user) => {
        const userLogs = [...logs].filter((log) => log.username === user.username);
        const mostRecentLog = userLogs.reduce((prev, current) => {
          if (!prev || current.dateAndTimeToLog > prev.dateAndTimeToLog) {
            return current;
          }
          return prev;
        }, null);

        return mostRecentLog;
      });
      return set({ mostRecentLogs: recentLogs });
    },
  };
});
