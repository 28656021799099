/* eslint-disable no-undef */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Stack } from '@mui/joy';
import { LoadingSpinner } from 'src/components';

export const spaceSeparated = (input) => {
  return input.replace(/([A-Z])/g, ' $1').trim();
};

/* Loading Spinner */
const LoadingContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 100px)',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: 3,
}));

export const Loading = ({ text }) => {
  return (
    <LoadingContainer>
      <LoadingSpinner sx={{ height: '5vmin !important', width: '5vmin !important' }} />
      <Typography variant="h3" sx={{ color: '#333333' }}>
        Loading {text}
      </Typography>
    </LoadingContainer>
  );
};
