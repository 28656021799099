import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Button as MuiButton } from '@mui/material';

const StyledButton = styled(MuiButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    borderRadius: '10px',
    color: theme.palette.offWhite.main,
    opacity: 0.8,
  },
  '&:hover': {
    backgroundColor: '#4C936F',
  },
  '& svg': {
    width: 25,
    height: 25,
  },
}));
const ExpandedButton = styled(StyledButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    height: '50px',
    width: '232px',
    textTransform: 'none',
    fontSize: '18px',
    justifyContent: 'flex-start',
    paddingLeft: '12.5px',
    alignItems: 'center',
  },
  '& svg': {
    marginRight: '15px',
  },
}));
const CollapsedButton = styled(StyledButton)({
  '&.MuiButtonBase-root': {
    height: '50px',
    width: '50px',
    minWidth: '50px',
    padding: '9px',
  },
});
const Text = styled('span')({
  marginTop: '3px',
});

export const SidebarButton = (props) => {
  const theme = useTheme();
  const { title, Icon, collapsed, selected } = props;
  const ButtonComponent = collapsed ? CollapsedButton : ExpandedButton;

  return (
    <ButtonComponent
      sx={
        selected
          ? {
              backgroundColor: '#4C936F',
              opacity: '1.0 !important',
            }
          : {}
      }
      aria-label={`${title}-tab`}
      {...props}>
      <Icon />
      {!collapsed ? <Text>{title}</Text> : null}
    </ButtonComponent>
  );
};
