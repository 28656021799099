import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { IconButton as MuiIconButton, Tooltip } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

export const IconButton = (props) => {
  const { icon, customIcon, label, tooltip, disabled, size } = props;

  // icon styling
  const theme = useTheme();
  const iconSX = {
    color: theme.palette.green.main,
    fill: theme.palette.green.main,
    height: size === 'small' ? '20px' : '25px',
    width: size === 'small' ? '20px' : '25px',
  };
  const buttonSX = {
    backgroundColor: `${
      disabled ? theme.palette.disabled.main : theme.palette.white.main
    } !important`,
    width: size === 'small' ? '25px' : '40px',
    height: size === 'small' ? '25px' : '40px',
    borderRadius: size === 'small' ? '7px' : '10px',
    '& .MuiTouchRipple-root': {
      borderRadius: size === 'small' ? '7px' : '10px',
    },
    boxShadow: '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '&:hover': {
      backgroundColor: `${theme.palette.white.hover} !important`,
    },
  };

  let IconComponent = customIcon;
  // heavily used icons
  if (icon === 'add') {
    IconComponent = <AddRoundedIcon sx={iconSX} />;
  } else if (icon === 'download') {
    IconComponent = <DownloadRoundedIcon sx={iconSX} />;
  } else if (icon === 'back') {
    IconComponent = <ArrowBackIosRoundedIcon sx={iconSX} />;
  } else if (icon === 'next') {
    IconComponent = <ArrowForwardIosRoundedIcon sx={iconSX} />;
  }

  const Button = (
    <MuiIconButton
      variant="contained"
      aria-label={label}
      sx={buttonSX}
      disabled={disabled}
      {...props}>
      {IconComponent}
    </MuiIconButton>
  );

  return tooltip ? <Tooltip title={tooltip}>{Button}</Tooltip> : Button;
};
