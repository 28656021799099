import React from 'react';
import { create } from 'zustand';
import { getBiometricData } from 'src/queries/biometrics';

const biometrics = [
  'HeartRate',
  'RestingHeartRate',
  'HeartRateVariability',
  'AverageWalkingHeartRate',
  'StepCount',
  'RespiratoryRate',
  // 'BloodPressureDiastolic',
  // 'BloodPressureSystolic',
  'OxygenSaturation',
];

const getBaseKey = (bio) => {
  return bio.charAt(0).toLowerCase() + bio.slice(1);
};

const biometricsLoading = biometrics.reduce((obj, bio) => {
  const baseKey = getBaseKey(bio);
  let objCopy = { ...obj };
  objCopy[baseKey + 'Loading'] = true;
  return objCopy;
}, {});

export const useBiometrics = create((set, get) => {
  const defaultBiometrics = {};
  biometrics.forEach((bio) => {
    const baseKey = getBaseKey(bio);
    defaultBiometrics[baseKey + 'Loading'] = true;
    defaultBiometrics[baseKey + 'All'] = [];
    defaultBiometrics[baseKey + 'CSV'] = '';
  });

  return {
    ...defaultBiometrics,
    biometrics,
    filteredData: [],
    selectedBio: 'HeartRate',
    setSelectedBio: (newBio) => {
      return set({ selectedBio: newBio });
    },
    getBiometrics: (group, appUsers) => {
      set(biometricsLoading);
      biometrics.forEach((bio) => {
        getBiometricData(group, bio, appUsers, (result, csvData) => {
          const key = getBaseKey(bio);
          return set({ [key + 'Loading']: false, [key + 'All']: result, [key + 'CSV']: csvData });
        });
      });
    },
    getBiometricsForUser: (bio, user) => {
      const key = getBaseKey(bio) + 'All';

      const store = get();
      const data = store[key];
      const filteredData = data.filter((item) => {
        return item.username === user.username;
      });

      return filteredData;
    },
  };
});
