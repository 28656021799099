/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://zhjhwr5a75.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "insights",
            "endpoint": "https://impo7gj8hj.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://6ciycxjbbvayldqqiar55sn5bm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-xklhnkpubbbadbbpsonefnbcju",
    "aws_cognito_identity_pool_id": "us-east-1:f0017267-cd5a-4e5a-88b5-65d6e13e9544",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_CtTqUJgug",
    "aws_user_pools_web_client_id": "7di3srtcbjhcntklr597cvog1d",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "dashboardcd4637d9c794470d8f3e1d579147d0f3150408-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
