import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { Paper, Typography } from '@mui/material';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';

const Container = styled(Stack)({
  justifyContent: 'stretch',
  width: '100%',
});
const StyledPaper = styled(Paper)(({ theme }) => ({
  '&.MuiPaper-root': {
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 4px 20px rgba(51, 51, 51, 0.2)',
    borderRadius: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    height: '210px',
    display: 'flex',
    textTransform: 'none',
    flexDirection: 'column',
    textAlign: 'center',
    width: '100%',
    padding: '20px',
    gap: '10px',
  },
  '& input': {
    width: '210px',
  },
  '& svg': {
    height: '40px',
    width: '40px',
    fill: theme.palette.gold.main,
  },
}));
const DragContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutral.hover,
  borderRadius: '15px',
  border: `2px dashed ${theme.palette.gold.main}`,
  alignItems: 'center',
  justifyContent: 'center',
  height: '210px',
  display: 'flex',
  textTransform: 'none',
  flexDirection: 'column',
  textAlign: 'center',
  width: '100%',
  padding: '20px',
  gap: '10px',
  '& input': {
    width: '210px',
  },
  '& svg': {
    height: '40px',
    width: '40px',
    fill: theme.palette.gold.main,
  },
}));

export const Dropzone = (props) => {
  const { file, setFile } = props;

  const [isDragging, setIsDragging] = useState(false);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    setFile(file);
  };

  return (
    <Container direction="row">
      {isDragging ? (
        <DragContainer
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={(event) => event.preventDefault()}
          onDrop={handleDrop}>
          <UploadFileRoundedIcon />
          <Typography variant="h4">Drag and Drop a PDF File Here</Typography>
        </DragContainer>
      ) : (
        <StyledPaper
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={(event) => event.preventDefault()}
          onDrop={handleDrop}>
          <UploadFileRoundedIcon />
          <Typography variant="h4">Drag and Drop a PDF File Here</Typography>
          <Typography variant="paragraph">or</Typography>
          <input type="file" accept=".pdf" onChange={handleFileSelect} />
        </StyledPaper>
      )}
    </Container>
  );
};
