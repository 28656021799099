import React from 'react';
import { create } from 'zustand';
import { Auth } from 'aws-amplify';

export const useAuth = create((set, get) => {
  return {
    isAuthorized: false,
    error: '',
    shouldResetPassword: false,
    user: {},
    isAdmin: false,
    getUser: async (onSuccess, onFailure) => {
      const { user, isAdmin } = get();

      return Auth.currentAuthenticatedUser()
        .then((newUser) => {
          onSuccess();
          const groups = user.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
          if (Object.keys(user).length === 0 || (!isAdmin && groups.includes('Admin'))) {
            set({ user: newUser, isAdmin: groups.includes('Admin') });
          }
        })
        .catch((e) => {
          console.log(e);
          onFailure();
          if (Object.keys(user).length > 0) {
            // set({
            //   user: {},
            //   isAdmin: false,
            // });
          }
        });
    },
    logout: async () => {
      try {
        await Auth.signOut();
        return set({ isAuthorized: false });
      } catch (error) {
        console.log(error);
      }
    },
    attemptSignIn: async (username, password) => {
      try {
        const user = await Auth.signIn(username, password);
        const groups = user.signInUserSession?.accessToken?.payload['cognito:groups'] || [];

        set({
          user: user,
          isAdmin: groups.includes('Admin'),
          error: '',
          shouldResetPassword: user.challengeName === 'NEW_PASSWORD_REQUIRED',
          isAuthenticated: true,
        });
      } catch (error) {
        if (error.toString().includes('UserNotFoundException')) {
          set({ error: 'Whoops! No user exists with that username.' });
        } else if (error.toString().includes('Username cannot be empty')) {
          set({ error: 'Whoops! Username cannot be empty.' });
        } else if (error.toString().includes('NotAuthorizedException')) {
          set({ error: 'Whoops! Incorrect username or password.' });
        } else {
          console.log('error signing in', error.toString());
        }
      }
    },
    createNewPassword: async (password, confirmPassword) => {
      if (password !== confirmPassword) {
        return set({ error: `Whoops! The passwords entered didn't match.` });
      }

      const { user } = get();

      try {
        await Auth.completeNewPassword(user, password);
        try {
          await Auth.signIn(user.username, password);
          return set({
            error: '',
            shouldResetPassword: false,
          });
        } catch (error) {
          console.log(error);
          return set({
            error: '',
            shouldResetPassword: false,
          });
        }
      } catch (error) {
        return set({
          error: `Whoops! There was an error setting your new password. Please check your connection and try again. If you continue to experience issues, please email us for assistance.`,
        });
      }
    },
  };
});
