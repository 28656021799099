import React from 'react';
import { styled } from '@mui/material/styles';
import { Card as MuiCard, Typography } from '@mui/material';
import { Stack } from '@mui/joy';

const StyledMuiCard = styled(MuiCard)(({ theme }) => ({
  '&.MuiCard-root': {
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 4px 20px rgba(51, 51, 51, 0.2)',
    borderRadius: '15px',
  },
}));
const Header = styled(Stack)({
  justifyContent: 'space-between',
});

export const Card = (props) => {
  const { title, headerContent, children } = props;
  return (
    <StyledMuiCard {...props}>
      <Stack direction="column" sx={{ height: '100%', overflow: 'hidden', padding: '30px' }}>
        {title || headerContent ? (
          <Header direction="row">
            <Typography variant="h3">{title}</Typography>
            {headerContent}
          </Header>
        ) : null}
        {children}
      </Stack>
    </StyledMuiCard>
  );
};
