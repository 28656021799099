import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';
import { Stack } from '@mui/joy';
import { BasicIconButton } from 'src/components';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';

const CollapsedStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(4),
  alignItems: 'center',
  width: '100%',
}));
const Logo = styled(Avatar)({
  width: 50,
  height: 50,
  borderRadius: '10px',
});

export const CollapsedSidebar = (props) => {
  const { toggleSidebar, children: sidebarItems } = props;

  return (
    <CollapsedStack>
      <Logo variant="rounded" alt="Cactus" src="/cactus.png" />
      <BasicIconButton
        aria-label="toggle-sidebar"
        onClick={toggleSidebar}
        style={{ marginTop: '10px' }}
        variant="white">
        <KeyboardDoubleArrowRightRoundedIcon fontSize="small" />
      </BasicIconButton>
      {sidebarItems}
    </CollapsedStack>
  );
};
