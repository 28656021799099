import React from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.gold.main,
}));

export const LoadingSpinner = (props) => {
  return <StyledCircularProgress {...props} />;
};
