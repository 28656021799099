import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Stack } from '@mui/joy';
import { useBiometrics, useDashStore } from 'src/hooks';
import { DataTable, IconButton, Card, LoadingSpinner, Select } from 'src/components';
import {
  downloadBiometricCSV,
  getBioKey,
  spaceSeparated,
  getBioDataByUser,
  bioDataPointsByUserColumns,
  Loading,
} from '../helpers';

const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 100px)',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: 3,
}));

const defaultSortModel = {
  field: 'username',
  sort: 'desc',
};

export const AllUsersBioTable = () => {
  const { setSelectedUser, userOptions, group, appUsers } = useDashStore();
  const biometricData = useBiometrics();
  const { selectedBio } = biometricData;

  const bioKey = getBioKey(selectedBio);
  const data = biometricData[bioKey + 'All'];
  const loading = biometricData[bioKey + 'Loading'];

  const tableData = getBioDataByUser(data);

  const title = spaceSeparated(selectedBio);

  const onRowClick = (row) => {
    const username = row.row.username;
    const newUserObject = userOptions.find((i) => i.username === username);
    setSelectedUser(newUserObject);
  };

  const downloadCSV = () => {
    downloadBiometricCSV(
      biometricData[bioKey + 'CSV'],
      `${group.name}_${selectedBio}_${new Date().toLocaleDateString()}.csv`,
      userOptions
    );
  };

  return (
    <Card
      sx={{ marginTop: '20px' }}
      title={`${title}: Data Points by User`}
      headerContent={
        <IconButton icon="download" onClick={downloadCSV} tooltip={`Download All ${title} Data`} />
      }>
      {loading ? (
        <Loading text={title} />
      ) : !appUsers.length ? (
        <Container>
          <Typography variant="instructions" style={{ fontSize: '24px' }}>
            No Users
          </Typography>
        </Container>
      ) : data.length ? (
        <Stack sx={{ marginTop: '20px', overflowY: 'auto' }}>
          <DataTable
            rowIDs={['username']}
            columns={bioDataPointsByUserColumns}
            data={tableData}
            initialSort={defaultSortModel}
            onRowClick={onRowClick}
          />
        </Stack>
      ) : (
        <Container>
          <Typography variant="instructions" style={{ fontSize: '24px' }}>
            No Data
          </Typography>
        </Container>
      )}
    </Card>
  );
};
