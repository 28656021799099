import React from 'react';
import { Tooltip } from '@mui/material';
import { Chip } from 'src/components';

const renderWithTooltip = (val, maxLength) => {
  if (val.length > maxLength) {
    return <Tooltip title={val}>{val.slice(0, maxLength)}...</Tooltip>;
  } else {
    return val;
  }
};

export const userColumns = [
  {
    Header: 'Email',
    accessor: 'email',
    customRender: (row) => {
      return renderWithTooltip(row.email, 12);
    },
  },
  {
    Header: 'Institution',
    accessor: 'institution',
  },
  {
    Header: 'Position',
    accessor: 'position',
    customRender: (row) => {
      return renderWithTooltip(row.position, 12);
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    defaultSort: true,
    defaultSortDesc: true,
    customRender: (row) => {
      return (
        <Chip
          variant={row.type === 'user' ? 'neutral' : row.type === 'admin' ? 'success' : ''}
          label={row.type}
        />
      );
    },
  },
  {
    Header: 'Groups & Roles',
    accessor: 'groups',
    customRender: (row) => {
      return row.type === 'admin'
        ? 'All'
        : row.groups
            ?.map((group) => {
              return `${group.groupName} (${group.role})`;
            })
            .join(', ');
    },
  },
];

export const groupColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Institution',
    accessor: 'institution',
  },
  {
    Header: 'Short Description',
    accessor: 'shortDescription',
  },
  {
    Header: '# Dash Users',
    accessor: 'numDashUsers',
  },
];
