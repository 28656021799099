import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { useTheme, styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { useForm, Controller } from 'react-hook-form';
import { useAdmin } from 'src/hooks';
import { Typography, FormControlLabel } from '@mui/material';
import { Modal, TextField, Button, Checkbox } from 'src/components';
import { ConfigurationCard } from '../ConfigurationCard';
import { ConsentFormConfig, DemographicsConfig } from './configPages';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import SickRoundedIcon from '@mui/icons-material/SickRounded';
import MedicationRoundedIcon from '@mui/icons-material/MedicationRounded';

const Container = styled(Stack)({
  margin: '15px 10px',
  gap: 20,
});
const Row = styled(Stack)({
  flexDirection: 'row',
  gap: 10,
});
const Separated = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const ConfigRow = styled(Stack)({
  flexDirection: 'row',
  display: 'flex',
  width: '100%',
  gap: 10,
  justifyContent: 'stretch',
});

export const GroupModal = (props) => {
  const theme = useTheme();
  const { open, setOpen, selectedGroup } = props;
  const { groups, selected } = useAdmin();
  const isNew = Object.keys(selected).length ? false : true;

  const defaultValues = isNew
    ? {
        groups: [],
      }
    : { ...selected };
  const form = useForm({
    defaultValues: isNew ? {} : selectedGroup,
  });
  const { register, handleSubmit, getValues, control, setValue, reset } = form;

  const [userGroups, setUserGroups] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [needsConsent, setNeedsConsent] = useState(false);
  const [file, setFile] = useState(null);
  const [page, setPage] = useState(0);

  useEffect(() => {
    reset(defaultValues);
    setNeedsConsent(defaultValues.needsConsent ? true : false);
    setErrorMessage('');
    setFile(null);
  }, [open]);

  const addNewGroup = () => {
    const newData = [...userGroups];
    newData.push({
      group: '',
      roles: [],
    });
    setUserGroups(newData);
  };

  const uploadConsentFormPDF = async (resolver) => {
    if (file) {
      try {
        const result = await Storage.put(`/consentForm/${getValues().name}`, file);
        const url = await Storage.get(result.key);
        resolver(url);
        console.log('File uploaded successfully!');
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const onSubmit = () => {
    let shouldContinue = true;
    let values = getValues();
    let submission = {
      name: values.name,
      institution: values.institution,
      shortDescription: values.shortDescription,
      fullDescription: values.fullDescription,
      needsConsent: values.needsConsent,
      prodPublic: values.prodPublic,
      devPublic: values.devPublic,
    };

    if (values.needsConsent && !file) {
      shouldContinue = false;
      setErrorMessage(
        "Oops! You have checked 'needs consent', but haven't finished setting up your Consent Form configs."
      );
    } else {
      uploadConsentFormPDF((url) => {
        let consentForm = {
          coordinates: {
            x: values.x,
            y: values.y,
          },
          includeDate: values.includeDate,
          page: values.signaturePage,
          url: url,
        };
        if (values.includeDate) {
          consentForm.dateCoordinates = {
            x: values.dateX,
            y: values.dateY,
          };
        }
        submission.consentForm = consentForm;
      });
    }

    if (shouldContinue) {
      console.log('submission: ', submission);
    }
  };

  return (
    <Modal
      label="create-group-modal"
      closeButton
      title={`${isNew ? 'Create' : 'Edit'} Group`}
      {...props}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container direction="column" sx={{ display: page === 0 ? 'flex' : 'none' }}>
          <Separated>
            <Typography variant="h4">Group Info</Typography>
            <Stack direction="row">
              <Controller
                name="devPublic"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    id="is-dev-public-input"
                    defaultChecked={field.value}
                    label="dev public"
                    {...field}
                  />
                )}
              />
              <Controller
                name="prodPublic"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    id="is-prod-public-input"
                    defaultChecked={field.value}
                    label="prod public"
                    {...field}
                  />
                )}
              />
              <Controller
                name="needsConsent"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    id="needs-consent-input"
                    label="needs consent"
                    {...field}
                    defaultChecked={field.value}
                    onChange={(val) => {
                      setNeedsConsent(val.target.checked);
                      setValue('needsConsent', val.target.checked);
                    }}
                  />
                )}
              />
            </Stack>
          </Separated>
          <Row>
            <Controller
              name="name"
              control={control}
              rules={{ required: true, maxLength: 20 }}
              render={({ field }) => (
                <TextField
                  id="name-input"
                  label="Name"
                  size="small"
                  required
                  InputProps={{
                    readOnly: !isNew,
                  }}
                  {...field}
                />
              )}
            />
            <Controller
              name="institution"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="institution-input"
                  label="Institution"
                  size="small"
                  required
                  {...field}
                />
              )}
            />
            <Controller
              name="shortDescription"
              control={control}
              rules={{ required: true, maxLength: 20 }}
              render={({ field }) => (
                <TextField
                  id="short-description-input"
                  label="Short Description"
                  size="small"
                  required
                  sx={{ minWidth: '200px' }}
                  {...field}
                />
              )}
            />
          </Row>
          <Row>
            <Controller
              name="fullDescription"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="full-description-input"
                  label="Full Description"
                  sx={{
                    flexGrow: 3,
                    height: '100px !important',
                    '& div': {
                      height: '100px !important',
                    },
                    '& textarea': {
                      height: '80px !important',
                    },
                  }}
                  size="small"
                  required
                  multiline
                  {...field}
                />
              )}
            />
          </Row>
          <Separated sx={{ marginTop: '10px' }}>
            <Typography variant="h4" sx={{ lineHeight: '40px' }}>
              Configurations
            </Typography>
          </Separated>
          <ConfigRow>
            {needsConsent ? (
              <ConfigurationCard
                id="consent-form-config-card"
                title="Consent Form"
                icon={<DescriptionRoundedIcon />}
                onClick={() => setPage(1)}
              />
            ) : null}
            <ConfigurationCard
              id="demographics-config-card"
              title="Demographics"
              icon={<PersonSearchRoundedIcon />}
              onClick={() => setPage(2)}
            />
            <ConfigurationCard
              id="symptoms-config-card"
              title="Symptoms"
              icon={<SickRoundedIcon />}
            />
            <ConfigurationCard
              id="medications-config-card"
              title="Medications"
              icon={<MedicationRoundedIcon />}
            />
          </ConfigRow>
          <Separated>
            <Typography
              variant="paragraph"
              color={theme.palette.error.main}
              sx={{ wordWrap: 'break-word', maxWidth: 300 }}>
              {errorMessage}
            </Typography>
            <Button
              type="submit"
              sx={{
                height: '40px !important',
                width: '200px',
                fontFamily: 'Comfortaa, Now, Arial',
              }}>
              Create Group
            </Button>
          </Separated>
        </Container>
        <ConsentFormConfig
          isNew={isNew}
          form={form}
          file={file}
          setFile={setFile}
          setPage={setPage}
          sx={{ display: page === 1 ? 'flex' : 'none' }}
        />
        <DemographicsConfig
          isNew={isNew}
          form={form}
          setPage={setPage}
          sx={{ display: page === 2 ? 'flex' : 'none' }}
        />
      </form>
    </Modal>
  );
};
