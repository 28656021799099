import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Alert as MuiAlert, AlertTitle, Box, Collapse } from '@mui/material';

const StyledAlert = styled(MuiAlert)(({ theme }) => ({
  '&.MuiAlert-root': {
    borderRadius: '15px',
  },
}));

export const Alert = (props) => {
  const { title, children, collapsible, sx, show, dismiss } = props;

  const [showAlert, setShowAlert] = useState(true);

  return collapsible ? (
    <Box sx={{ width: '100%' }}>
      <Collapse in={show ? show : show}>
        <StyledAlert
          {...props}
          sx={sx}
          severity="info"
          onClose={() => {
            dismiss ? dismiss() : setShowAlert(false);
          }}>
          <AlertTitle>{title}</AlertTitle>
          {children}
        </StyledAlert>
      </Collapse>
    </Box>
  ) : (
    <StyledAlert {...props} sx={sx}>
      <AlertTitle>{title}</AlertTitle>
      {children}
    </StyledAlert>
  );
};
