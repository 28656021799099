import React from 'react';
import { styled } from '@mui/material/styles';
import { Button as MuiButton, Typography } from '@mui/material';
import { Stack } from '@mui/joy';

const StyledMuiButton = styled(MuiButton)(({ theme }) => ({
  '&.MuiButton-root': {
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 4px 20px rgba(51, 51, 51, 0.2)',
    borderRadius: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    height: '120px',
    display: 'flex',
    textTransform: 'none',
    flexGrow: 1,
    '&:hover': {
      backgroundColor: theme.palette.white.hover,
      cursor: 'pointer',
    },
    '& svg': {
      height: '50px',
      width: '50px',
      fill: theme.palette.gold.main,
    },
    '& .MuiTouchRipple-root': {
      color: theme.palette.gold.main,
    },
  },
}));
const StyledStack = styled(Stack)({
  flexDirection: 'column',
  gap: 5,
  alignItems: 'center',
});

export const ConfigurationCard = (props) => {
  const { icon, title } = props;
  return (
    <StyledMuiButton {...props}>
      <StyledStack>
        {icon}
        <Typography variant="paragraph">{title}</Typography>
      </StyledStack>
    </StyledMuiButton>
  );
};
