export const formatUsers = (users, groups) => {
  const newUsers = users.map((user) => {
    const { roles, ...userNoRoles } = user;
    const userGroups = roles?.map((role) => {
      const group = groups.find((g) => g.id === role.groupID);
      return {
        groupName: group.name,
        role: role.role,
      };
    });
    return {
      ...userNoRoles,
      groups: userGroups,
    };
  });
  return newUsers;
};

export const formatGroups = (users, groups) => {
  const newGroups = groups.map((group) => {
    let numDashUsers = 0;
    users.forEach((user) => {
      if (user.type !== 'admin' && user.roles?.some((role) => role.groupID === group.id)) {
        numDashUsers++;
      }
    });
    return {
      ...group,
      numDashUsers,
    };
  });
  return newGroups;
};
