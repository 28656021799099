import React, { useState, useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { Typography } from '@mui/material';
import { useDashStore, useAuth } from 'src/hooks';
import { Select, LoadingSpinner } from 'src/components';
import { SidebarButton } from './SidebarButton';
import { topItems, bottomItems } from './items';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

const CollapsedStack = styled(Stack)({
  marginTop: '19px',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 'calc(100vh - 165px)',
});
const ExpandedStack = styled(Stack)({
  marginLeft: '24px',
  marginTop: '48px',
  justifyContent: 'space-between',
  height: 'calc(100vh - 147.5px)',
});
const ItemsStack = styled(Stack)({
  gap: '12px',
});
const CenteredStack = styled(Stack)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 30,
});

export const SidebarItems = (props) => {
  const { collapsed } = props;
  const theme = useTheme();
  const StackComponent = collapsed ? CollapsedStack : ExpandedStack;

  const { logout, isAdmin } = useAuth();

  const { group, userOptions, setGroup, groups, sidebarOptions, activePage, setActivePage } =
    useDashStore();

  const setGroupByID = (val) => {
    let groupToSet = groups.find((item) => item.name === val);
    setGroup(groupToSet);
  };

  return (
    <StackComponent>
      {Object.keys(group).length ? (
        <Stack>
          <Select
            id={'group-select'}
            label={'Select Group:'}
            options={groups.map((g) => {
              return g.name;
            })}
            value={group.name}
            setValue={setGroupByID}
            sx={[
              collapsed ? {} : { width: '232px' },
              {
                marginBottom: theme.spacing(3),
              },
            ]}
            labelSX={{
              color: theme.palette.offWhite.main,
            }}
            collapsed={collapsed}
          />
          <ItemsStack>
            {sidebarOptions.map((option, idx) => {
              const details = topItems[option];
              return (
                <SidebarButton
                  key={idx}
                  title={details.title}
                  Icon={details.Icon}
                  collapsed={collapsed}
                  selected={activePage === option}
                  onClick={() => {
                    setActivePage(option);
                  }}
                />
              );
            })}
          </ItemsStack>
        </Stack>
      ) : (
        // loading groups message
        <CenteredStack>
          <LoadingSpinner />
          <Typography variant="instructions" sx={{ color: '#ffffff' }}>
            Loading Groups
          </Typography>
        </CenteredStack>
      )}
      <Stack>
        <ItemsStack>
          {bottomItems.map((details, idx) => {
            return details.id !== 'admin' || isAdmin ? (
              <SidebarButton
                key={idx}
                title={details.title}
                Icon={details.Icon}
                collapsed={collapsed}
                selected={activePage === details.id}
                onClick={() => {
                  setActivePage(details.id);
                }}
              />
            ) : null;
          })}
          <SidebarButton
            title={'Logout'}
            Icon={LogoutRoundedIcon}
            collapsed={collapsed}
            onClick={() => {
              logout();
            }}
          />
        </ItemsStack>
      </Stack>
    </StackComponent>
  );
};
