/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useDashStore } from 'src/hooks';
import { Sidebar } from './Sidebar';
import { SessionTimeout } from './SessionTimeout';
import { Dashboard } from '../Dashboard';
import { Biometrics } from '../Biometrics';
import { WellnessLogs } from '../WellnessLogs';
import { Insights } from '../Insights';
import { Admin } from '../Admin';
import { Nutrition } from '../Nutrition';
import { Exercise } from '../Exercise';

const expandedDrawerWidth = 280;
const collapsedDrawerWidth = 98;

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.offWhite.main,
  width: '100%',
  height: '100dvh',
  justifyContent: 'flex-end',
  display: 'flex',
}));

const pages = {
  // dashboard: Dashboard,
  wellnessLogs: WellnessLogs,
  biometrics: Biometrics,
  nutrition: Nutrition,
  exercise: Exercise,
  // insights: Insights,
  admin: Admin,
};

export const Main = () => {
  const { activePage, sidebarOpen, setSidebarOpen } = useDashStore();
  const Page = pages[activePage];

  return (
    <Container>
      <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} />
      <React.Fragment>
        <Page
          sx={{
            width: `calc(100vw - ${sidebarOpen ? expandedDrawerWidth : collapsedDrawerWidth}px)`,
            padding: '30px',
          }}
        />
      </React.Fragment>
      <SessionTimeout />
    </Container>
  );
};
