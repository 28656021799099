import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  // '&.MuiAlert-root': {
  //   borderRadius: '15px',
  // },
  '& .MuiDataGrid-row': {
    height: 'max-content',
    maxHeight: 'none !important',
  },
  '& .grey': {
    backgroundColor: '#F8F8F8',
  },
  '& .MuiDataGrid-cell': {
    fontFamily: 'Comfortaa, Now, Arial',
    fontWeight: 500,
    fontSize: '12px',
    color: '#333333',
    letterSpacing: '0.15px',
    borderBottom: 'none',
    height: 'max-content',
    maxHeight: 'none !important',
    '&:focus': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnHeader': {
    '&:focus': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-overlayWrapper': {
    height: '100%',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Comfortaa, Now, Arial',
    fontWeight: 700,
    fontSize: '12px',
    color: '#828282',
    letterSpacing: '0.15px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    minHeight: 'calc(100dvh - 360px) !important',
  },
}));

export const DataTable = (props) => {
  const { data, columns, pageSize = 10, rowIDs, initialSort, hasPagination, ...otherProps } = props;

  const [sortModel, setSortModel] = useState([
    initialSort || { field: columns[0].field, sort: 'desc' },
  ]);

  const getRowId = (row) => {
    let id = '';
    if (rowIDs) {
      id = rowIDs.reduce((prev, curr) => {
        return prev + '-' + row[curr];
      }, '');
    } else {
      id = row.id;
    }
    return id;
  };

  return (
    <StyledDataGrid
      rows={data}
      columns={columns}
      getRowId={getRowId}
      initialState={
        hasPagination
          ? {
              pagination: {
                paginationModel: {
                  pageSize: pageSize,
                },
              },
            }
          : {}
      }
      pageSizeOptions={hasPagination ? [10, 20, 50, 100] : []}
      disableRowSelectionOnClick
      getRowClassName={(params) => {
        return params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'grey';
      }}
      disableColumnMenu
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      hideFooter={!hasPagination}
      paginationMode={hasPagination ? 'client' : 'server'}
      {...otherProps}
    />
  );
};
