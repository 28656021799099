import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Stack } from '@mui/joy';

export const dateTickFormat = (tick) => {
  const date = new Date(tick);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

// formatting simple line graph data (no granularity)
export const formatBasicData = (data) => {
  return data
    .map((item) => {
      return {
        date: new Date(item.startDate).getTime(),
        value: parseInt(item.value),
      };
    })
    .sort((a, b) => {
      return a.date - b.date;
    });
};

// for some data, get the least recent startDate
export const getFirstDateInData = (data) => {
  const dates = data.map((item) => new Date(item.startDate).getTime());
  const earliestDate = new Date(Math.min(...dates));
  return earliestDate;
};

// for some data, get the most recent startDate
export const getLastDateInData = (data) => {
  const dates = data.map((item) => new Date(item.startDate).getTime());
  const latestDate = new Date(Math.max(...dates));
  return latestDate;
};

// get # days between a start date and end date
export const getDaysBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const timeDiff = Math.abs(end.getTime() - start.getTime());
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return daysDiff;
};

export const ChartContainer = styled(Stack)({
  height: '100%',
  paddingBottom: '15px',
});

const NoDataContainer = styled(Stack)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

export const NoData = () => {
  return (
    <NoDataContainer>
      <Typography variant="instructions" style={{ fontSize: '24px' }}>
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export const TooltipContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: '10px',
  borderRadius: '15px',
  border: `2px solid ${theme.palette.green.main}`,
}));

export const getGroupedData = (data, granularityOptions, granularity) => {
  const start = getFirstDateInData(data);
  const end = getLastDateInData(data);
  let view = granularity || '24 Hours';
  if (!granularity) {
    const difference = getDaysBetweenDates(start, end);
    if (difference <= 7) {
      view = '3 Hours';
    } else if (difference <= 30) {
      view = '6 Hours';
    } else if (difference <= 90) {
      view = '12 Hours';
    }
  }
  const avgData = granularityOptions[view](data, start, end);

  return [view, avgData];
};
