import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { TextField, FormControl, Button } from 'src/components';
import { Auth, API } from 'aws-amplify';
import { useAuth } from 'src/hooks';
import { ChangePasswordModal } from '../ChangePasswordModal';

export const LoginForm = () => {
  const { attemptSignIn, error } = useAuth();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await attemptSignIn(username, password);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  // async function signUp() {
  //   let apiName = 'AdminQueries';
  //   let path = '/createUser';
  //   let myInit = {
  //     body: {
  //       username: 'liz',
  //       email: 'elizabethannehensley@gmail.com',
  //       firstName: 'Elizabeth',
  //       lastName: 'Hensley',
  //       institution: 'Nanbar Health',
  //       roles: [
  //         {
  //           team: 'BetaTesters',
  //           roles: ['']
  //         }
  //       ],
  //     },
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
  //     },
  //   };
  //   return await API.post(apiName, path, myInit);
  // }

  return (
    <React.Fragment>
      <FormControl sx={{ width: '60%', margin: '0px auto' }}>
        <TextField
          id="username-input"
          label="Username"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <TextField
          id="password-input"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
        <Button onClick={onSubmit} loading={isLoading}>
          Login
        </Button>
        {error ? <Typography variant="instructions">{error}</Typography> : null}
      </FormControl>
      <ChangePasswordModal />
    </React.Fragment>
  );
};
