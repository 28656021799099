import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { InputLabel, MenuItem, Select as MuiSelect } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';

const StyledMuiSelect = styled(MuiSelect)(({ theme }) => ({
  '& label': {
    color: theme.palette.gray2.main,
    '&.Mui-focused': {
      color: theme.palette.green.main,
    },
  },
  '&.MuiInputBase-root': {
    fontWeight: 500,
    height: '50px',
    boxShadow: theme.shadows[0],
    borderRadius: '10px',
    backgroundColor: theme.palette.white.main,
    color: theme.palette.gray1.main,
    '&:focus-visible': {
      outline: 'none',
    },
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.white.main,
    },
    ':hover:not(.Mui-disabled, .Mui-error):before': {
      borderBottom: '0px',
    },
    ':hover': {
      backgroundColor: theme.palette.white.hover,
    },
    '&::before': {
      borderBottom: '0px',
    },
    '&::after': {
      borderBottom: '0px',
    },
  },
  '& .MuiSelect-select': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
}));
const StyledCollapsedSelect = styled(MuiSelect)(({ theme }) => ({
  '&.MuiInputBase-root': {
    height: '50px',
    width: '50px',
    boxShadow: theme.shadows[0],
    borderRadius: '10px',
    backgroundColor: theme.palette.white.main,
    color: theme.palette.gray1.main,
    '&:focus-visible': {
      outline: 'none',
    },
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.white.main,
    },
    ':hover:not(.Mui-disabled, .Mui-error):before': {
      borderBottom: '0px',
    },
    ':hover': {
      backgroundColor: theme.palette.white.hover,
    },
    '&::before': {
      borderBottom: '0px',
    },
    '&::after': {
      borderBottom: '0px',
    },
  },
  '& .MuiSelect-select': {
    fontSize: '0px',
    padding: '10px',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  '& .MuiSelect-iconOutlined': {
    position: 'absolute',
    left: 13,
    top: 25,
  },
}));
const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '12px',
  paddingLeft: '12px',
}));
const StyledArrowIcon = styled(ExpandMoreRoundedIcon)(({ theme }) => ({
  fill: theme.palette.green.main,
}));
const StyledGroupsIcon = styled(GroupsRoundedIcon)(({ theme }) => ({
  fill: theme.palette.green.main,
  width: '30px',
  height: '30px',
}));

const SelectComponent = (props) => {
  const { collapsed } = props;

  return collapsed ? (
    <StyledCollapsedSelect
      renderValue={() => {
        return <StyledGroupsIcon />;
      }}
      {...props}
    />
  ) : (
    <StyledMuiSelect {...props} />
  );
};

export const Select = (props) => {
  const { id, label, options, value, setValue, collapsed, labelSX, stackSX, customRender } = props;

  const menuItems = options.map((option) => {
    return (
      <MenuItem key={option} value={option}>
        {customRender ? customRender(option) : option}
      </MenuItem>
    );
  });

  return (
    <Stack sx={stackSX}>
      {label && !collapsed ? (
        <StyledInputLabel id={`${id}-label`} sx={labelSX}>
          {label}
        </StyledInputLabel>
      ) : null}
      <SelectComponent
        labelId={`${id}-label`}
        id={id}
        label={label}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        collapsed={collapsed}
        IconComponent={StyledArrowIcon}
        {...props}>
        {menuItems}
      </SelectComponent>
    </Stack>
  );
};
