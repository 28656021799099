import React, { useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { Auth } from 'aws-amplify';
import { Typography, LinearProgress } from '@mui/material';
import zxcvbn from 'zxcvbn';
import { useAuth } from 'src/hooks';
import { Modal, TextField, Button } from 'src/components';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
});
const Row = styled(Stack)({
  flexDirection: 'row',
  gap: 10,
});
const Separated = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});
const ProgressBarStack = styled(Stack)({});
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  '&.MuiLinearProgress-root': {
    width: 'max(min(350px, 30vw), 200px)',
    backgroundColor: '#e8e8e8',
    margin: '20px 0px 10px',
    height: '10px',
    borderRadius: '5px',
  },
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.gold.main,
  },
}));

const capitalLetterRegex = /.*[A-Z].*/;
const lowercaseLetterRegex = /.*[a-z].*/;
const numberRegex = /.*\d.*/;

export const ChangePasswordModal = (props) => {
  const { user, shouldResetPassword, error, createNewPassword } = useAuth();
  const theme = useTheme();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await createNewPassword(password, confirmPassword);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const getPasswordStrength = () => {
    return zxcvbn(password).score;
    // The `score` ranges from 0 to 4 (0 being weak and 4 being strong)
  };

  const passwordIsValid =
    capitalLetterRegex.test(password) &&
    lowercaseLetterRegex.test(password) &&
    numberRegex.test(password);
  const passwordIsLongEnough = password.length >= 8;

  return (
    <Modal
      open={shouldResetPassword}
      label="change-password-modal"
      title={`Create New Password`}
      {...props}
      setOpen={() => {}}>
      <Container>
        <Typography variant="h2" align="center">
          Welcome {user.username}!
        </Typography>
        <Typography
          variant="instructions"
          align="center"
          sx={{ width: '70vw', margin: '0px 20px' }}>
          Please create a new password for your account:
        </Typography>

        {/* Password */}
        <TextField
          id="password-input"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required
          sx={{ width: 'max(min(350px, 30vw), 200px)' }}
        />
        <TextField
          id="confirm-password-input"
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          required
          sx={{ width: 'max(min(350px, 30vw), 200px)' }}
        />

        {/* Password Instructions 1 */}
        <Stack direction="row" sx={{ marginTop: '20px' }}>
          {passwordIsValid ? (
            <CheckCircleRoundedIcon sx={{ fontSize: '20px', fill: theme.palette.green.main }} />
          ) : (
            <CancelRoundedIcon sx={{ fontSize: '20px', fill: theme.palette.error.main }} />
          )}
          <Typography variant="instructions" align="center" sx={{ marginLeft: '10px' }}>
            Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number.
          </Typography>
        </Stack>

        {/* Password Instructions 1 */}
        <Stack direction="row">
          {passwordIsLongEnough ? (
            <CheckCircleRoundedIcon sx={{ fontSize: '20px', fill: theme.palette.green.main }} />
          ) : (
            <CancelRoundedIcon sx={{ fontSize: '20px', fill: theme.palette.error.main }} />
          )}
          <Typography variant="instructions" align="center" sx={{ marginLeft: '10px' }}>
            Password must be at least 8 characters long.
          </Typography>
        </Stack>

        {/* Password Strength Meter */}
        <ProgressBarStack>
          <StyledLinearProgress variant="determinate" value={getPasswordStrength() * 25} />
          <Typography variant="paragraph" align="left">
            Password Strength
          </Typography>
        </ProgressBarStack>

        {/* Error Message */}
        {error.length ? (
          <Typography
            variant="instructions"
            align="center"
            sx={{ width: '70vw', margin: '0px 20px', color: theme.palette.error.main }}>
            {error}
          </Typography>
        ) : null}

        {/* Submit Button */}
        <Button
          sx={{
            width: 'max(min(350px, 30vw), 200px)',
            fontFamily: 'Comfortaa, Now, Arial',
            marginBottom: '30px',
          }}
          disabled={
            !password.length ||
            !confirmPassword.length ||
            getPasswordStrength() < 3 ||
            !passwordIsValid ||
            !passwordIsLongEnough
          }
          onClick={onSubmit}
          loading={isLoading}>
          Submit
        </Button>
      </Container>
    </Modal>
  );
};
