import React, { useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { useForm, Controller } from 'react-hook-form';
import { Typography, Divider } from '@mui/material';
import { TextField, Button, Checkbox, Table, IconButton } from 'src/components';

const Container = styled(Stack)({
  margin: '15px 0px',
  gap: 3,
  minWidth: '400px',
});
const Row = styled(Stack)({
  flexDirection: 'row',
  gap: 3,
});
const DemographicsForm = styled(Stack)({
  flexDirection: 'column',
  gap: 3,
});
const Separated = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const DemographicsConfig = (props) => {
  const theme = useTheme();

  const { isNew, form, setPage } = props;
  const { getValues, control, setValue } = form;

  const [errorMessage, setErrorMessage] = useState('');
  const [demographicsList, setDemographicsList] = useState(getValues().demographics || []);
  const [optionsList, setOptionsList] = useState([]);
  const [showingDemographicsForm, setShowingDemographicsForm] = useState(false);

  const demographicsForm = useForm({
    defaultValues: {},
  });
  const {
    getValues: getDemographicsValues,
    control: demographicsControl,
    setValue: setDemographicsValue,
    reset: resetDemographics,
  } = demographicsForm;

  const setDemographicsForm = (row) => {
    console.log(row);
  };

  const addNewOption = () => {
    const newData = [...optionsList];
    newData.push({
      option: '',
    });
    setOptionsList(newData);
  };

  const submitOption = () => {};

  // TODO
  const onSubmit = () => {
    let shouldContinue = true;
    const values = getValues();

    if (demographicsList.length) {
      setErrorMessage('Oops! Please fill out all required fields.');
      shouldContinue = false;
    }

    if (shouldContinue) {
      setErrorMessage('');
      setPage(0);
    }
  };

  return (
    <Container direction="column" {...props}>
      <Separated>
        <Typography variant="h4">Demographics Questions</Typography>
        <IconButton
          icon="add"
          tooltip="Add Demographics Question"
          onClick={() => setShowingDemographicsForm(true)}
        />
      </Separated>
      <Table
        columns={[
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: 'Type',
            accessor: 'type',
          },
          {
            Header: '',
            accessor: 'delete',
            width: 10,
          },
        ]}
        data={demographicsList}
        setData={setDemographicsList}
        rowsDeletable
        onRowClick={setDemographicsForm}
      />
      {showingDemographicsForm ? (
        <DemographicsForm>
          <Divider />
          <Separated>
            <Typography variant="h4">Create/Edit Demographics Question</Typography>
          </Separated>
          <Row>
            <Controller
              name="id"
              control={demographicsControl}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="demographics-question-id-input"
                  label="ID"
                  size="small"
                  required
                  {...field}
                />
              )}
            />
            <Controller
              name="type"
              control={demographicsControl}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="demographics-question-type-input"
                  label="Type"
                  size="small"
                  required
                  {...field}
                />
              )}
            />
          </Row>
          <Separated>
            <Typography variant="h5">Options</Typography>
            <IconButton icon="add" tooltip="Add Option" onClick={addNewOption} />
          </Separated>
          <Table
            columns={[
              {
                Header: 'Option',
                accessor: 'option',
              },
              {
                Header: '',
                accessor: 'delete',
                width: 10,
              },
            ]}
            data={optionsList}
            setData={(data) => {
              setOptionsList(data);
              setDemographicsValue('options', data);
            }}
            rowsEditable
            rowsDeletable
          />
          <Button
            sx={{
              height: '40px !important',
              width: '200px',
              fontFamily: 'Comfortaa, Now, Arial',
            }}
            onClick={onSubmit}>
            Add Demographics Question
          </Button>
          <Divider />
        </DemographicsForm>
      ) : null}
      <Separated>
        <Typography
          variant="paragraph"
          color={theme.palette.error.main}
          sx={{ wordWrap: 'break-word', maxWidth: 300 }}>
          {errorMessage}
        </Typography>
        <Button
          sx={{
            height: '40px !important',
            width: '200px',
            fontFamily: 'Comfortaa, Now, Arial',
          }}
          onClick={onSubmit}>
          Back to Group
        </Button>
      </Separated>
    </Container>
  );
};
