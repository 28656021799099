import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { useDashStore } from 'src/hooks';
import { Header, Select } from 'src/components';

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.offWhite.main,
  height: '100dvh',
  display: 'flex',
}));

export const Dashboard = (props) => {
  const { user, setUser, userOptions } = useDashStore();

  return (
    <Container {...props}>
      <Header title="Dashboard">
        <Select
          id="user-select"
          options={userOptions}
          value={user}
          setValue={setUser}
          sx={{ minWidth: '200px' }}
        />
      </Header>
    </Container>
  );
};
