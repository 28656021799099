import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

const Button = styled(IconButton)({
  height: 40,
  width: 40,
  margin: 'auto 0px',
  '& svg': {
    height: 24,
    width: 24,
  },
});

const WhiteButton = styled(Button)(({ theme }) => ({
  '& svg': {
    fill: theme.palette.offWhite.main,
  },
  '&:hover': {
    backgroundColor: '#4C936F',
  },
}));

export const BasicIconButton = (props) => {
  const { variant } = props;

  if (variant === 'white') {
    return <WhiteButton {...props} />;
  } else {
    return <Button {...props} />;
  }
};
