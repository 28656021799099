import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { TextField as MuiTextField, InputAdornment, IconButton } from '@mui/material';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

const StyledMuiTextField = styled(MuiTextField)(({ theme }) => ({
  '&.MuiTextField-root': {
    height: '50px',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  '& label': {
    color: theme.palette.gray2.main,
    '&.Mui-focused': {
      color: theme.palette.green.main,
    },
  },
  '& .MuiInputBase-root': {
    boxShadow: theme.shadows[0],
    borderRadius: '10px',
    backgroundColor: theme.palette.white.main,
    color: theme.palette.gray1.main,
    '&.Mui-focused': {
      backgroundColor: theme.palette.white.main,
    },
    ':hover:not(.Mui-disabled, .Mui-error):before': {
      borderBottom: '0px',
    },
    '&:focus-visible': {
      outline: 'none',
    },
    ':hover': {
      backgroundColor: theme.palette.white.hover,
    },
    '&::before': {
      borderBottom: '0px',
    },
    '&::after': {
      borderBottom: '0px',
    },
  },
  '& .MuiInputBase-readOnly': {
    backgroundColor: theme.palette.disabled.main,
    borderRadius: '10px',
    ':hover': {
      backgroundColor: theme.palette.disabled.main,
    },
  },
}));
const SmallMuiTextField = styled(StyledMuiTextField)({
  '&.MuiTextField-root': {
    height: '40px',
    '& .MuiFormLabel-root': {
      fontSize: '14px',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
    },
  },
});

const PasswordField = (props) => {
  const { TextFieldComponent } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <TextFieldComponent
      variant="filled"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ marginRight: '10px' }}>
            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
              {showPassword ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
      type={showPassword ? 'text' : 'password'}
    />
  );
};

export const TextField = (props) => {
  const { size, type } = props;

  let TextFieldComponent = StyledMuiTextField;
  if (size === 'small') {
    TextFieldComponent = SmallMuiTextField;
  }

  if (type === 'password') {
    return <PasswordField TextFieldComponent={TextFieldComponent} {...props} />;
  }

  return <TextFieldComponent variant="filled" type={type} {...props} />;
};
