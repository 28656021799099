import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Stack } from '@mui/joy';
import { useDashStore, useBiometrics } from 'src/hooks';
import { Table, IconButton, Card, Select } from 'src/components';
import { getBiometricData } from 'src/queries/biometrics';
import { spaceSeparated, getBioKey, Loading, downloadBiometricCSV } from '../helpers';
import {
  HeartRateChart,
  StepCountChart,
  RestingHRChart,
  HRVariabilityChart,
  AvgWalkingHRChart,
  RespiratoryRateChart,
  OxygenSaturationChart,
} from './charts';

const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 100px)',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: 3,
}));

const biometricChartMap = {
  HeartRate: HeartRateChart,
  RestingHeartRate: RestingHRChart,
  HeartRateVariability: HRVariabilityChart,
  AverageWalkingHeartRate: AvgWalkingHRChart,
  StepCount: StepCountChart,
  RespiratoryRate: RespiratoryRateChart,
  // 'BloodPressureDiastolic',
  // 'BloodPressureSystolic',
  OxygenSaturation: OxygenSaturationChart,
};

export const BiometricsChart = (props) => {
  const { selectedUser, appUsers } = useDashStore();
  const { selectedBio, getBiometricsForUser } = useBiometrics();

  const Chart = biometricChartMap[selectedBio];

  const biometricsKey = getBioKey(selectedBio) + 'All';
  const loadingKey = getBioKey(selectedBio) + 'Loading';

  return (
    <Card sx={{ marginTop: '20px', height: '100%' }}>
      {!appUsers.length ? (
        <Container>
          <Typography variant="instructions" style={{ fontSize: '24px' }}>
            No Users
          </Typography>
        </Container>
      ) : useBiometrics()[loadingKey] ? (
        <Loading />
      ) : useBiometrics()[biometricsKey].length ? (
        <Chart data={getBiometricsForUser(selectedBio, selectedUser)} />
      ) : (
        <Container>
          <Typography variant="instructions" style={{ fontSize: '24px' }}>
            No Data
          </Typography>
        </Container>
      )}
    </Card>
  );
};
