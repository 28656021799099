import { API, graphqlOperation } from 'aws-amplify';
import { apiQuery } from './base';
import { getDashUser, listAppUsers } from '../graphql/queries';

export const getGroupsForUser = async (user, setGroups) => {
  try {
    const dashUserQuery = await API.graphql({
      ...graphqlOperation(getDashUser, {
        id: user.attributes?.sub || user.signInUserSession?.idToken?.payload?.sub,
      }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
    const dashUser = dashUserQuery.data.getDashUser;
    const groupIDs = dashUser.roles.map((item) => {
      return item.groupID;
    });

    const groupFilters = groupIDs.map((id) => ({ id: { eq: id } }));

    const groupQuery = `query GetGroupsByIDs($groupFilters: [ModelGroupFilterInput!]) {
      listGroups(filter: { or: $groupFilters }) {
        items {
          id
          app
          name
          demographics {
            id
            options
            question
            type
          }
          education
          medications
          symptoms
          surveys
        }
      }
    }`;

    return apiQuery(groupQuery, { groupFilters }, (data) => setGroups(data.listGroups.items));
  } catch (error) {
    console.log(error);
  }
};

export const getAppUsersForGroup = (groupID, setUsers) => {
  const query = `
    query GetAppUsersForGroup($filter: [ModelAppUserFilterInput!]) {
      listAppUsers(
        filter: { or: $filter }
      ) {
        items {
          id
          demographics
          groups
          usernames
        }
      }
    }
  `;

  return apiQuery(
    query,
    {
      filter: {
        groups: {
          contains: groupID,
        },
      },
    },
    (data) => setUsers(data.listAppUsers.items, groupID)
  );
};
