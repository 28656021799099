import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Stack } from '@mui/joy';
import { useDashStore, useNutrition } from 'src/hooks';
import { DataTable, IconButton, Card, Select } from 'src/components';
import {
  getAllUserColumns,
  getSingleUserColumns,
  downloadLogCSV,
  Loading,
  Container,
} from './helpers';

const SelectableTableTitle = (props) => {
  const { tableView, setTableView } = props;
  return (
    <Stack
      direction="row"
      sx={{ gap: 2, margin: '-10px 0px 0px -10px', padding: '10px 0px 0px 10px' }}>
      <Select
        id="table-view-select"
        options={['All', 'Most Recent']}
        value={tableView}
        setValue={setTableView}
        sx={{ minWidth: '150px', maxHeight: '40px' }}
      />
      Logs{tableView === 'Most Recent' ? ' (for each user)' : ''}
    </Stack>
  );
};

const defaultSortModel = {
  field: 'dateAndTimeToLog',
  sort: 'desc',
};

export const NutritionTable = () => {
  const { logs: allLogs, mostRecentLogs, loadingLogs } = useNutrition();
  const { selectedUser, selectedAppUserData, appUsers, loadingNewAppUsers } = useDashStore();
  const allUsers = selectedUser.id === 'All Users';

  const [tableView, setTableView] = useState('All');
  const [columns, setColumns] = useState(getAllUserColumns());

  useEffect(() => {
    setColumns(allUsers ? getAllUserColumns() : getSingleUserColumns());
  }, [allUsers]);

  const tableData = allUsers
    ? tableView === 'All'
      ? allLogs
      : mostRecentLogs
    : allLogs.filter((log) => {
        return log.username === selectedUser.username;
      });

  return (
    <Card
      sx={{ marginTop: '20px' }}
      title={
        allUsers ? (
          <SelectableTableTitle tableView={tableView} setTableView={setTableView} />
        ) : (
          `${selectedUser.username} Logs`
        )
      }
      headerContent={
        <IconButton
          icon="download"
          onClick={() => {
            downloadLogCSV(
              tableData,
              `nutrition${allUsers ? '' : `_${selectedUser.username}`}${
                tableView === 'Most Recent' && allUsers ? '_Recent' : ''
              }.csv`
            );
          }}
          tooltip={`Download Nutrition Logs`}
        />
      }>
      {loadingLogs || loadingNewAppUsers ? (
        <Loading />
      ) : !appUsers.length ? (
        <Container>
          <Typography variant="instructions" style={{ fontSize: '24px' }}>
            No Users
          </Typography>
        </Container>
      ) : allLogs.length ? (
        <Stack sx={{ marginTop: '20px', overflowY: 'auto' }}>
          <DataTable
            rowIDs={allUsers ? ['username', 'dateAndTimeSubmitted'] : ['dateAndTimeSubmitted']}
            columns={columns}
            data={tableData}
            initialSort={defaultSortModel}
            hasPagination
          />
        </Stack>
      ) : (
        <Container>
          <Typography variant="instructions" style={{ fontSize: '24px' }}>
            No Data
          </Typography>
        </Container>
      )}
    </Card>
  );
};
