import React, { useState, useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { useForm, Controller } from 'react-hook-form';
import { useAdmin } from 'src/hooks';
import { Typography } from '@mui/material';
import {
  Modal,
  TextField,
  IconButton,
  Table,
  Button,
  Checkbox,
  Info,
  LoadingSpinner,
} from 'src/components';

const Container = styled(Stack)({
  margin: '15px 0px',
  gap: 20,
});
const Row = styled(Stack)({
  flexDirection: 'row',
  gap: 10,
});
const Separated = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const UserModal = (props) => {
  const theme = useTheme();
  const { open, setOpen } = props;
  const { groups, users, selected, editUser, createUser, deleteUser } = useAdmin();
  const isNew = Object.keys(selected).length ? false : true;

  const defaultValues = isNew
    ? {
        groups: [],
        type: 'user',
      }
    : { ...selected, username: selected.owner };

  const { register, handleSubmit, getValues, control, reset, setValue } = useForm({
    defaultValues,
  });

  const [userGroups, setUserGroups] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const admin = defaultValues.type === 'admin';
    reset(defaultValues);
    setIsAdmin(isNew ? false : admin);
    setUserGroups(isNew || admin ? [] : defaultValues.groups);
    setErrorMessage('');
  }, [open]);

  const addNewGroup = () => {
    const newData = [...userGroups];
    newData.push({
      group: '',
      roles: [],
    });
    setUserGroups(newData);
  };

  const onDelete = () => {
    deleteUser(selected);
    setOpen(false);
  };

  const onSubmit = () => {
    let shouldContinue = true;
    let { groups: g, username, createdAt, updatedAt, __typename, owner, ...values } = getValues();
    if (
      !isAdmin &&
      (!userGroups.length ||
        !userGroups.every((item) => {
          return item.groupName && item.role;
        }))
    ) {
      shouldContinue = false;
      setErrorMessage(
        'User must belong to at least one valid group, and all groups must have valid values for all fields.'
      );
    }

    if (isNew && users.some((user) => user.owner === username)) {
      shouldContinue = false;
      setErrorMessage('Sorry, this username already exists!');
    }

    if (shouldContinue) {
      if (isAdmin) {
        values.roles = groups.map((group) => {
          return { role: 'admin', groupID: group.id };
        });
      } else {
        values.roles = userGroups.map((group) => {
          let id = groups.find((i) => i.name === group.groupName).id;
          return { role: group.role, groupID: id };
        });
      }

      if (isNew) {
        createUser({ username, ...values });
      } else {
        editUser(values);
      }
      setOpen(false);
    }
  };

  return (
    <Modal
      label="create-user-modal"
      closeButton
      title={`${isNew ? 'Create' : 'Edit'} User`}
      {...props}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container direction="column">
          <Separated>
            <Typography variant="h4">User Info</Typography>
            <Controller
              name="type"
              control={control}
              render={({ field }) => {
                return (
                  <Stack direction="row">
                    <Checkbox
                      id="is-admin-input"
                      defaultChecked={field.value === 'admin'}
                      label="admin"
                      {...field}
                      onChange={(val) => {
                        setIsAdmin(val.target.checked);
                        setValue('type', val.target.checked ? 'admin' : 'user');
                      }}
                      disabled={!isNew}
                    />
                  </Stack>
                );
              }}
            />
          </Separated>
          <Row>
            <Controller
              name="username"
              control={control}
              rules={{ required: true, maxLength: 20 }}
              render={({ field }) => (
                <TextField
                  id="username-input"
                  label="Username"
                  size="small"
                  required
                  InputProps={{
                    readOnly: !isNew,
                  }}
                  {...field}
                />
              )}
            />
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="first-name-input"
                  label="First Name"
                  size="small"
                  required
                  InputProps={{
                    readOnly: !isNew,
                  }}
                  {...field}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="last-name-input"
                  label="Last Name"
                  size="small"
                  required
                  InputProps={{
                    readOnly: !isNew,
                  }}
                  {...field}
                />
              )}
            />
          </Row>
          <Row>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="email-input"
                  label="Email"
                  sx={{ flexGrow: 3 }}
                  size="small"
                  required
                  InputProps={{
                    readOnly: !isNew,
                  }}
                  {...field}
                />
              )}
            />
          </Row>
          <Row>
            <Controller
              name="institution"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="institution-input"
                  label="Institution"
                  size="small"
                  required
                  {...field}
                />
              )}
            />
            <Controller
              name="position"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="position-input"
                  label="Position"
                  size="small"
                  sx={{ flexGrow: 2 }}
                  required
                  {...field}
                />
              )}
            />
          </Row>
          {isAdmin ? null : (
            <Separated sx={{ marginTop: '10px' }}>
              <Typography variant="h4" sx={{ lineHeight: '40px' }}>
                Groups
              </Typography>
              <IconButton
                icon="add"
                tooltip="Add Group"
                onClick={addNewGroup}
                disabled={!groups.length}
              />
            </Separated>
          )}
          {isAdmin ? null : !userGroups ? (
            <LoadingSpinner sx={{ height: '5vmin !important', width: '5vmin !important' }} />
          ) : (
            <Table
              columns={[
                {
                  Header: 'Group',
                  accessor: 'groupName',
                  isEditable: true,
                  editType: 'select',
                  options: groups.map((g) => g.name),
                },
                {
                  Header: 'Role',
                  accessor: 'role',
                  isEditable: true,
                  editType: 'select',
                  options: ['clinician', 'coordinator', 'billing'],
                },
                {
                  Header: '',
                  accessor: 'edit',
                  width: 10,
                },
                {
                  Header: '',
                  accessor: 'delete',
                  width: 10,
                },
              ]}
              data={userGroups}
              setData={setUserGroups}
              rowsEditable
              rowsDeletable
            />
          )}
          <Separated>
            <Typography
              variant="paragraph"
              color={theme.palette.error.main}
              sx={{ wordWrap: 'break-word', maxWidth: isNew ? 300 : 225 }}>
              {errorMessage}
            </Typography>
            <Stack direction="row" style={{ gap: 20 }}>
              {!isNew ? (
                <Button
                  sx={{
                    height: '40px !important',
                    width: '150px',
                    fontFamily: 'Comfortaa, Now, Arial',
                    backgroundColor: `${theme.palette.error.main} !important`,
                    '&:hover': {
                      backgroundColor: `${theme.palette.error.hover} !important`,
                    },
                  }}
                  onClick={onDelete}>
                  Delete User
                </Button>
              ) : null}
              <Button
                type="submit"
                sx={{
                  height: '40px !important',
                  width: '150px',
                  fontFamily: 'Comfortaa, Now, Arial',
                }}>
                {isNew ? 'Create' : 'Edit'} User
              </Button>
            </Stack>
          </Separated>
        </Container>
      </form>
    </Modal>
  );
};
