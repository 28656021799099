import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Main, Login } from './routes';
import { useAuth } from 'src/hooks';
import { LoadingSpinner } from 'src/components';

import './fonts/comfortaa.css';
import '@aws-amplify/ui-react/styles.css';

const Container = styled('div')({
  margin: '-8px',
});
const LoadingContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.offWhite.main,
  width: '100%',
  height: '100dvh',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
}));

const LoadingPage = () => {
  // TODO: make this a nice splash page
  return (
    <LoadingContainer>
      <LoadingSpinner sx={{ height: '10vmin !important', width: '10vmin !important' }} />
    </LoadingContainer>
  );
};

const AuthHandle = ({ setIsAuthenticated, setIsLoading }) => {
  const successAuth = () => {
    setIsAuthenticated(true);
    setIsLoading(false);
  };
  const failureAuth = () => {
    setIsAuthenticated(false);
    setIsLoading(false);
  };
  useAuth().getUser(successAuth, failureAuth);
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const AuthWrapper = ({ children }) => {
    return isLoading ? (
      <LoadingPage />
    ) : isAuthenticated ? (
      <React.Fragment>{children}</React.Fragment>
    ) : (
      <Login />
    );
  };

  return (
    <Container>
      <React.Fragment>
        <AuthHandle setIsAuthenticated={setIsAuthenticated} setIsLoading={setIsLoading} />
      </React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthWrapper>
                <Main />
              </AuthWrapper>
            }
          />
        </Routes>
      </BrowserRouter>
    </Container>
  );
};

export default App;
