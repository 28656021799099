import { createTheme } from '@mui/material/styles';
import { NowBlack, NowBold, NowMedium, NowRegular, NowLight, NowThin } from './fonts';

export const theme = createTheme({
  shadows: [
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
    '0px 4px 20px rgba(51, 51, 51, 0.2)',
  ],
  spacing: [5, 10, 16, 24, 32, 48, 64],
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
  palette: {
    green: {
      main: '#4C936F',
      hover: 'rgba(75, 147, 111, 0.85)',
    },
    lightGreen: {
      main: '#68AD89',
    },
    darkGreen: {
      main: '#276445',
    },
    gold: {
      main: '#FEA71C',
      hover: 'rgba(253, 166, 29, 0.85)',
    },
    darkBrown: {
      main: '#683717',
    },
    lightBrown: {
      main: '#BC5939',
    },
    cream: {
      main: '#FDF4E5',
    },
    offWhite: {
      main: '#F0F0F0',
    },
    white: {
      main: '#FFFFFF',
      hover: '#F4F4F4',
    },
    gray1: {
      main: '#333333',
    },
    gray2: {
      main: '#828282',
    },
    gray3: {
      main: '#B6B6B6',
    },
    error: {
      main: '#E8354D',
      hover: 'rgba(232, 53, 77, 0.85)',
    },
    warning: {
      main: '#FC7E1E',
    },
    neutral: {
      main: '#C6C6C8',
    },
    disabled: {
      main: '#D6D6D6',
    },
  },
  typography: {
    fontFamily: 'Now, Comfortaa, Arial',
    h1: {
      fontSize: '2.2rem',
      fontWeight: 800,
      letterSpacing: '0.4px',
      lineHeight: '2.5rem',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '50px',
      color: '#333333',
    },
    h3: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '40px',
      color: '#828282',
    },
    h4: {
      fontSize: '14px',
      fontWeight: 600,
      color: '#333333',
    },
    h5: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#828282',
    },
    instructions: {
      fontSize: '1rem',
      fontFamily: 'Comfortaa, Now, Arial',
      fontWeight: 400,
      lineHeight: '1.5rem',
      color: '#828282',
    },
    promo: {
      fontSize: 'min(max(24px, 3vmin), 35px)',
      fontWeight: 500,
      lineHeight: 'min(max(30px, 4vmin), 40px)',
      color: '#828282',
      fontFamily: 'Comfortaa, Now, Arial',
    },
    paragraph: {
      fontSize: '12px',
      fontWeight: 400,
      fontFamily: 'Comfortaa, Now, Arial',
      color: '#333333',
    },
    nanbar: {
      color: '#FDF4E5',
      fontFamily: 'Comfortaa, Now, Arial',
      fontSize: '24px',
      lineHeight: '26.76px',
      fontWeight: 600,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Now';
          font-style: black;
          font-display: swap;
          font-weight: 800;
          src: local('Now'), local('Now-Black'), url(${NowBlack}) format('otf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Now';
          font-style: bold;
          font-display: swap;
          font-weight: 600;
          src: local('Now'), local('Now-Bold'), url(${NowBold}) format('otf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Now';
          font-style: medium;
          font-display: swap;
          font-weight: 500;
          src: local('Now'), local('Now-Medium'), url(${NowMedium}) format('otf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Now';
          font-style: regular;
          font-display: swap;
          font-weight: 400;
          src: local('Now'), local('Now-Regular'), url(${NowRegular}) format('otf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Now';
          font-style: light;
          font-display: swap;
          font-weight: 300;
          src: local('Now'), local('Now-Light'), url(${NowLight}) format('otf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Now';
          font-style: thin;
          font-display: swap;
          font-weight: 200;
          src: local('Now'), local('Now-Thin'), url(${NowThin}) format('otf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});
