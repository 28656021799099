/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { Card, Typography } from '@mui/material';
import { BasicIconButton } from 'src/components';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = styled(Stack)({
  justifyContent: 'stretch',
});
const Header = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '0px 10px 0px 15px',
});
const StyledCard = styled(Card)(({ theme }) => ({
  '&.MuiCard-root': {
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 4px 20px rgba(51, 51, 51, 0.2)',
    borderRadius: '15px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '210px',
    display: 'flex',
    textTransform: 'none',
    flexDirection: 'column',
    textAlign: 'center',
    width: '200px',
    padding: '10px 0px 0px 0px',
    gap: '10px',
  },
}));
const Preview = styled('div')(({ theme }) => ({
  maxHeight: '160px',
  width: '100%',
  backgroundColor: theme.palette.neutral.main,
  '& div': {
    height: '160px',
    minHeight: '160px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .textLayer': {
    display: 'none',
  },
  '& .annotationLayer': {
    display: 'none',
  },
}));
const StyledBasicIconButton = styled(BasicIconButton)({
  height: '30px',
  width: '30px',
  '& svg': {
    width: '20px',
    height: '20px',
  },
});
const FileName = styled(Typography)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  marginTop: '3px',
});

export const FilePreview = (props) => {
  const theme = useTheme();
  const { file, setFile } = props;

  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = file;
    link.download = 'file.pdf';
    link.click();
  };

  return (
    <Container direction="row">
      <StyledCard>
        <Header>
          <FileName variant="paragraph">{file.name}</FileName>
          <StyledBasicIconButton>
            <DeleteOutlineRoundedIcon
              sx={{ fill: theme.palette.error.main }}
              onClick={() => setFile(null)}
            />
          </StyledBasicIconButton>
        </Header>
        <Preview onClick={handleDownload}>
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            options={{ workerSrc: pdfjs.GlobalWorkerOptions.workerSrc }}>
            <Page pageNumber={1} width={100} />
          </Document>
        </Preview>
      </StyledCard>
    </Container>
  );
};
