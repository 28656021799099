import { API, graphqlOperation } from 'aws-amplify';
import { apiQuery, apiBatchQuery } from './base';

const listExercises = /* GraphQL */ `
  query ListExercises($filter: ModelExerciseFilterInput, $limit: Int, $nextToken: String) {
    listExercises(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateAndTimeSubmitted
        appUserID
        exerciseType
        resistance
        numSets
        repsPerSet
        duration
        durationUnit
        exertion
        distance
        distanceUnit
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const mapExercises = (logs, appUsers, resolver) => {
  const exercises = [];
  logs.forEach((log) => {
    const { appUserID, id, ...logNoID } = log;
    const appUser = appUsers.find((user) => user.id === appUserID);
    if (appUser) {
      const { username } = appUser;

      const fullLog = {
        username: username,
        ...logNoID,
      };
      exercises.push(fullLog);
    }
  });
  resolver(exercises);
};

export const getExerciseLogsForUsers = async (appUsers, setExerciseLogs) => {
  const appUserFilters = appUsers.map((user) => {
    return { appUserID: { eq: user.id } };
  });
  let exerciseLogs = [];

  try {
    await apiBatchQuery(
      listExercises,
      { filter: { or: appUserFilters } },
      'listExercises',
      (data) => {
        exerciseLogs = data;
        mapExercises(exerciseLogs, appUsers, setExerciseLogs);
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export const getExerciseLogsForUser = (appUserID, setExerciseLogs) => {
  return apiQuery(listExercises, { filter: { appUserID: { eq: appUserID } } }, setExerciseLogs);
};
