import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';

const StyledChip = styled(MuiChip)(({ theme }) => ({
  '&.MuiChip-root': {
    height: '20px',
    borderRadius: '9px',
    fontSize: '10px',
  },
}));

export const Chip = (props) => {
  const theme = useTheme();
  const { variant } = props;

  return (
    <StyledChip
      sx={{
        backgroundColor:
          variant === 'error'
            ? theme.palette.error.main
            : variant === 'success'
            ? theme.palette.green.main
            : variant === 'warning'
            ? theme.palette.warning.main
            : theme.palette.neutral.main,
        color: variant === 'neutral' ? theme.palette.gray1.main : theme.palette.offWhite.main,
      }}
      {...props}
    />
  );
};
