import { API, graphqlOperation } from 'aws-amplify';
import { insightsQuery } from './base';
import { getFromStorage } from './base';

function delay(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

export const getInsightsForGroup = async (groupName, resolver) => {
  await getFromStorage(`${groupName}/insights.json`, 'json', resolver);
};

export const runInsights = async (group, users) => {
  insightsQuery(
    {
      groupID: group.id,
      groupName: group.name,
      users: users,
    },
    '/runInsights'
  );
};

export const getInsightsWithOptions = (group, options, users) => {
  insightsQuery(
    {
      groupID: group.id,
      groupName: group.name,
      users: users,
    },
    '/runInsights'
  );
};
