import React from 'react';
import { styled } from '@mui/material/styles';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';

const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: '14px',
  },
}));
const StyledCheckbox = styled(MuiCheckbox)(({ theme }) => ({
  '& svg': {
    height: 20,
    width: 20,
    fill: theme.palette.green.main,
  },
  '& span': {
    color: theme.palette.green.main,
  },
  '&.MuiButtonBase-root': {
    color: theme.palette.green.main,
    '&:hover': {
      backgroundColor: 'rgba(75, 147, 111, 0.04)',
    },
  },
}));

export const Checkbox = (props) => {
  const { label } = props;

  if (label) {
    return <StyledLabel control={<StyledCheckbox {...props} />} label={label} />;
  } else {
    return <StyledCheckbox {...props} />;
  }
};
