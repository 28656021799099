import React from 'react';

/* PLOT LAYOUT */

const axisConfig = {
  showbackground: false,
  showline: false,
  zeroline: false,
  showgrid: false,
  showticklabels: false,
  title: '',
};

export const plotLayout = (windowWidth, windowHeight, sidebarOpen) => {
  let width = windowWidth - 240;
  if (sidebarOpen) {
    width -= 182;
  }
  return {
    showlegend: false,
    scene: {
      xaxis: axisConfig,
      yaxis: axisConfig,
      zaxis: axisConfig,
    },
    camera: {
      eye: { x: 1.25, y: 1.25, z: 1.25 }, // Adjust the camera position
      center: { x: 0, y: 0, z: 0 }, // Adjust the center of the scene
    },
    responsive: true,
    useResizeHandler: true,
    autosize: true,
    margin: {
      l: 5,
      r: 5,
      t: 10,
      b: 10,
      pad: 0,
      autoexpand: true,
    },
    width: width,
    height: windowHeight - 200,
  };
};

/* PLOT TRACES */

function assignColour(correlation) {
  if (correlation <= 0) {
    return '#ffa500'; // red
  } else {
    return '#9eccb7'; // green
  }
}

function assignThickness(correlation, benchmarkThickness = 15, scalingFactor = 1) {
  return benchmarkThickness * Math.pow(Math.abs(correlation), scalingFactor);
}

function getLabel(data, nodes) {
  return `<b>${nodes[data.source][1]} & ${nodes[data.target][1]}:</b><br />${data.value}`;
}

export const getTraces = (insightsData) => {
  const { Xn, Yn, Zn, Xe, Ye, Ze, data, Edges, Labels } = insightsData.network;

  // Create node trace
  const nodeTrace = {
    x: Xn,
    y: Yn,
    z: Zn,
    mode: 'markers',
    name: 'symp/biom',
    marker: {
      symbol: 'circle',
      size: 15,
      color: 'saddlebrown',
    },
    text: Labels.map((i) => {
      return i[1];
    }),
    hoverinfo: 'text',
    type: 'scatter3d',
  };

  // Assign colors and widths to edges
  const edge_colours = [];
  const edge_widths = [];
  const edge_labels = [];
  const corr_lst = data.links.map((link) => link.value);

  for (let i = 0; i < data.links.length - 1; i++) {
    let corr = data.links[i];
    edge_colours.push(assignColour(corr.value));
    edge_widths.push(assignThickness(corr.value));
    edge_labels.push(getLabel(corr, data.nodes));
  }

  const edge_traces = Edges.map((e, i) => {
    const a = i * 3;
    const b = a + 1;
    const c = a + 2;
    return {
      x: [Xe[a], Xe[b], Xe[c]],
      y: [Ye[a], Ye[b], Ye[c]],
      z: [Ze[a], Ze[b], Ze[c]],
      mode: 'lines',
      hoverinfo: 'text',
      text: edge_labels[i],
      line: {
        width: edge_widths[i],
        color: edge_colours[i],
      },
      type: 'scatter3d',
    };
  });

  return [nodeTrace, ...edge_traces];
};
