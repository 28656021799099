import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Drawer, Avatar, Typography, IconButton } from '@mui/material';
import { Stack } from '@mui/joy';
import { BasicIconButton } from 'src/components';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';

const ExpandedStack = styled(Stack)({
  alignItems: 'flex-start',
});
const LogoRow = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginLeft: theme.spacing(3),
  justifyContent: 'space-between',
  width: '232px',
}));
const Logo = styled(Avatar)({
  width: 50,
  height: 50,
  borderRadius: '10px',
});
const NanbarHealth = styled(Typography)({
  padding: '0px 10px',
  width: '100px',
});

export const ExpandedSidebar = (props) => {
  const { toggleSidebar, children: sidebarItems } = props;

  return (
    <ExpandedStack>
      <LogoRow direction="row">
        <Stack direction="row">
          <Logo variant="rounded" alt="Cactus" src="/cactus.png" />
          <NanbarHealth variant="nanbar">Nanbar Health</NanbarHealth>
        </Stack>
        <BasicIconButton aria-label="toggle-sidebar" onClick={toggleSidebar} variant="white">
          <KeyboardDoubleArrowLeftRoundedIcon fontSize="small" />
        </BasicIconButton>
      </LogoRow>
      {sidebarItems}
    </ExpandedStack>
  );
};
