import React, { useState, useEffect } from 'react';
import { create } from 'zustand';
import { createEducation, attachEducationToGroups } from 'src/queries/education';

let educationItems = [
  {
    title: 'DASH: Getting Started',
    type: 'pdf',
    emoji: '🫑',
    ref: 'DASHGettingStarted.pdf',
    tags: ['Nutrition', 'Food'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'DASH: Making the Move',
    type: 'pdf',
    emoji: '🍌',
    ref: 'MakingTheMove.pdf',
    tags: ['Nutrition', 'Food'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Food Labels',
    type: 'pdf',
    emoji: '🥫',
    ref: 'FoodLabels.pdf',
    tags: ['Nutrition', 'Food'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: "A Good Night's Sleep",
    type: 'pdf',
    emoji: '💤',
    ref: 'GettingAGoodNightsSleep.pdf',
    tags: ['Tips', 'Sleep'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Fats and Oils',
    type: 'pdf',
    emoji: '🧈',
    ref: 'HealthierFatsAndOils.pdf',
    tags: ['Nutrition', 'Food'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Healthy Sleep',
    type: 'pdf',
    emoji: '😴',
    ref: 'HealthySleep.pdf',
    tags: ['Sleep'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Healthy Weight',
    type: 'pdf',
    emoji: '🍎',
    ref: 'HealthyWeight.pdf',
    tags: ['Nutrition', 'Food'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Heart Smart Basics',
    type: 'pdf',
    emoji: '❤️',
    ref: 'HeartSmartBasics.pdf',
    tags: ['Nutrition', 'General', 'Heart'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Herbs and Spices',
    type: 'pdf',
    emoji: '🧂',
    ref: 'HerbsAndSpices.pdf',
    tags: ['Nutrition', 'Food'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Lung Health',
    type: 'pdf',
    emoji: '🫁',
    ref: 'LungHealth.pdf',
    tags: ['Tips', 'General'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Move More',
    type: 'pdf',
    emoji: '🚴🏽',
    ref: 'MoveMore.pdf',
    tags: ['Exercise', 'Physical Activity'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Stress Tips',
    type: 'pdf',
    emoji: '🧘',
    ref: 'StressTips.pdf',
    tags: ['Tips', 'General', 'Heart', 'Mental', 'Self-care'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Heart Health',
    type: 'pdf',
    emoji: '🫀',
    ref: 'TipsForHeartHealth.pdf',
    tags: ['Tips', 'Mental', 'Stress', 'Self-care', 'Heart'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const useEducation = create((set, get) => {
  return {
    createEducation: () => {
      educationItems.forEach((educationItem) => {
        createEducation(educationItem, []);
      });
      // return createEducation(education);
    },
    attachEducationToGroups: (educationID, groups) => {
      return attachEducationToGroups(educationID, groups);
    },
  };
});
