import React from 'react';

/* Feeling Emojis in SVG Format */

const Feeling1 = (
  <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.9691 32.7778C28.3339 32.7778 34.3043 27.057 34.3043 20C34.3043 12.943 28.3339 7.22223 20.9691 7.22223C13.6042 7.22223 7.63379 12.943 7.63379 20C7.63379 27.057 13.6042 32.7778 20.9691 32.7778Z"
      stroke="#E8354D"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M12.7822 13.2889C15.7403 13.2548 16.9991 12.2433 17.6426 11.43"
      stroke="#E8354D"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.9065 26.4222C26.9065 24.9822 25.2442 22.4661 20.9636 22.5722C16.9201 22.6729 15.0381 25.0822 15.0381 26.5228C15.0381 27.9633 17.5144 25.6278 20.865 25.6939C24.5276 25.7661 26.9123 27.8622 26.9123 26.4222H26.9065Z"
      fill="#E8354D"
      stroke="#E8354D"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M13.5244 15.8167C16.548 16.6156 16.773 16.322 18.281 16.4872C18.9003 16.5551 17.1041 17.9706 14.059 18.93"
      stroke="#E8354D"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.1553 13.2889C26.1972 13.2548 24.9385 12.2433 24.2949 11.43"
      stroke="#E8354D"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.4134 15.8167C25.3898 16.6156 25.1648 16.322 23.6568 16.4872C23.0376 16.5551 24.8338 17.9706 27.8789 18.93"
      stroke="#E8354D"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Feeling2 = (
  <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.9691 32.7778C28.3339 32.7778 34.3043 27.057 34.3043 20C34.3043 12.943 28.3339 7.22223 20.9691 7.22223C13.6042 7.22223 7.63379 12.943 7.63379 20C7.63379 27.057 13.6042 32.7778 20.9691 32.7778Z"
      stroke="#FC7E1E"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M17.4905 17.2222C17.4905 18.1427 16.7108 18.8889 15.7511 18.8889C14.7914 18.8889 14.0117 18.1426 14.0117 17.2222C14.0117 16.3027 14.7914 15.5556 15.7511 15.5556C16.7108 15.5556 17.4905 16.3027 17.4905 17.2222Z"
      fill="#FC7E1E"
    />
    <path
      d="M27.927 17.2222C27.927 18.1427 27.1474 18.8889 26.1876 18.8889C25.2279 18.8889 24.4482 18.1426 24.4482 17.2222C24.4482 16.3027 25.2279 15.5556 26.1876 15.5556C27.1474 15.5556 27.927 16.3027 27.927 17.2222Z"
      fill="#FC7E1E"
    />
    <path
      d="M16.3311 25.5556C17.2474 24.1347 19.1745 23.2584 21.2136 23.3312C23.0856 23.3981 24.7741 24.2553 25.6078 25.5556"
      stroke="#FC7E1E"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Feeling3 = (
  <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.9691 32.7778C28.3339 32.7778 34.3043 27.057 34.3043 20C34.3043 12.943 28.3339 7.22223 20.9691 7.22223C13.6042 7.22223 7.63379 12.943 7.63379 20C7.63379 27.057 13.6042 32.7778 20.9691 32.7778Z"
      stroke="#C6C6C8"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M15.751 23.8889H26.1873"
      stroke="#C6C6C8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4905 17.2222C17.4905 18.1427 16.7108 18.8889 15.7511 18.8889C14.7914 18.8889 14.0117 18.1426 14.0117 17.2222C14.0117 16.3027 14.7914 15.5556 15.7511 15.5556C16.7108 15.5556 17.4905 16.3027 17.4905 17.2222Z"
      fill="#C6C6C8"
    />
    <path
      d="M27.927 17.2222C27.927 18.1427 27.1474 18.8889 26.1876 18.8889C25.2279 18.8889 24.4482 18.1426 24.4482 17.2222C24.4482 16.3027 25.2279 15.5556 26.1876 15.5556C27.1474 15.5556 27.927 16.3027 27.927 17.2222Z"
      fill="#C6C6C8"
    />
  </svg>
);

const Feeling4 = (
  <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.9691 32.7778C28.3339 32.7778 34.3043 27.057 34.3043 20C34.3043 12.943 28.3339 7.22223 20.9691 7.22223C13.6042 7.22223 7.63379 12.943 7.63379 20C7.63379 27.057 13.6042 32.7778 20.9691 32.7778Z"
      stroke="#68AD89"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6596 24.9607C24.9785 25.8697 23.0369 26.3884 20.9689 26.3884C18.901 26.3884 16.9595 25.8698 15.2783 24.9607"
      stroke="#68AD89"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4905 17.2222C17.4905 18.1427 16.7108 18.8889 15.7511 18.8889C14.7914 18.8889 14.0117 18.1426 14.0117 17.2222C14.0117 16.3027 14.7914 15.5556 15.7511 15.5556C16.7108 15.5556 17.4905 16.3027 17.4905 17.2222Z"
      fill="#68AD89"
    />
    <path
      d="M27.927 17.2222C27.927 18.1427 27.1474 18.8889 26.1876 18.8889C25.2279 18.8889 24.4482 18.1426 24.4482 17.2222C24.4482 16.3027 25.2279 15.5556 26.1876 15.5556C27.1474 15.5556 27.927 16.3027 27.927 17.2222Z"
      fill="#68AD89"
    />
  </svg>
);

const Feeling5 = (
  <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.9251 32.7778C28.29 32.7778 34.2604 27.057 34.2604 20C34.2604 12.943 28.29 7.22223 20.9251 7.22223C13.5602 7.22223 7.58984 12.943 7.58984 20C7.58984 27.057 13.5602 32.7778 20.9251 32.7778Z"
      stroke="#276445"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3873 23.1333C29.3942 23.9887 29.2227 24.8368 28.8828 25.6278C21.6412 27.3111 14.1387 25.8167 12.9443 25.5556C12.6203 24.786 12.4568 23.9631 12.4631 23.1333H12.5268C12.5268 23.1333 21.1078 25.1278 29.2771 23.1722L29.3873 23.1333Z"
      stroke="#276445"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.8829 25.6278C27.8451 28 25.2012 29.6445 20.9455 29.6445C16.6492 29.6445 13.9706 27.9611 12.9443 25.5556C14.1387 25.8167 21.6412 27.3111 28.8829 25.6278Z"
      stroke="#276445"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.4283 18.002C18.2131 17.5378 17.8619 17.1434 17.4174 16.8666C16.973 16.5898 16.4542 16.4424 15.9241 16.4424C15.394 16.4424 14.8753 16.5898 14.4308 16.8666C13.9863 17.1434 13.6352 17.5378 13.4199 18.002"
      stroke="#276445"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.4303 18.002C28.215 17.5378 27.8639 17.1434 27.4194 16.8666C26.9749 16.5898 26.4562 16.4424 25.9261 16.4424C25.3959 16.4424 24.8772 16.5898 24.4327 16.8666C23.9883 17.1434 23.6371 17.5378 23.4219 18.002"
      stroke="#276445"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export const feelingsMap = {
  0: <React.Fragment />,
  1: Feeling1,
  2: Feeling2,
  3: Feeling3,
  4: Feeling4,
  5: Feeling5,
};
