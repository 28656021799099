import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { Typography, Avatar } from '@mui/material';
import { LoginForm } from '../LoginForm';

const GreenRow = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.green.main,
  width: '100%',
  height: '15dvh',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

const LoginStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.offWhite.main,
  width: '100%',
  height: '70dvh',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

export const LoginSmallScreen = () => {
  return (
    <Stack>
      <GreenRow />
      <LoginStack>
        <Avatar alt="Cactus" src="/logo512.png" sx={{ width: 75, height: 75 }} />
        <Typography variant="h1">Welcome to Nanbar</Typography>
        <LoginForm />
      </LoginStack>
      <GreenRow />
    </Stack>
  );
};
