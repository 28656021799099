import React, { useState } from 'react';
import { ConfigurationCard } from '../ConfigurationCard';
import { Dropzone } from './Dropzone';
import { FilePreview } from './FilePreview';

export const FileUploader = (props) => {
  const { file, setFile } = props;

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  return file ? (
    <FilePreview file={file} setFile={setFile} />
  ) : (
    <Dropzone file={file} setFile={setFile} />
  );
};
