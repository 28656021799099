import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { Typography } from '@mui/material';

const HeaderRow = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  flexWrap: 'wrap',
}));
const RightRow = styled(Stack)(({ theme }) => ({
  gap: 20,
  flexWrap: 'wrap',
}));

export const Header = (props) => {
  const { title, children: rightContent, level = 2 } = props;
  return (
    <HeaderRow direction="row" {...props}>
      <Typography variant={`h${level}`}>{title}</Typography>
      <RightRow direction="row">{rightContent}</RightRow>
    </HeaderRow>
  );
};
