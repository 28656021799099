import { apiQuery, adminQuery, apiCreate, apiDelete } from './base';
import {
  createDashUser,
  createGroup,
  createEducation,
  updateGroup,
  updateDashUser,
  deleteDashUser,
} from 'src/graphql/mutations';
import { listGroups, listDashUsers } from 'src/graphql/queries';
import { v4 as uuidv4 } from 'uuid';

const createUserAttributes = async (inputs) => {
  return await apiCreate(createDashUser, inputs);
};

export const createUser = async (inputs) => {
  const { username, ...otherInputs } = inputs;
  const { email, type } = otherInputs;
  // create user
  const user = await adminQuery(
    {
      username: username,
      email: email,
    },
    '/createUser'
  );
  // add user to group
  await adminQuery(
    {
      username: username,
      groupname: type === 'admin' ? 'Admin' : 'User',
    },
    '/addUserToGroup'
  );
  // create user attributes object
  let sub = user.result.User.Attributes[0].Value;
  createUserAttributes({
    id: sub,
    owner: `${sub}::${username}`,
    ...otherInputs,
  });
};

export const getAllGroups = (resolver) => {
  return apiQuery(listGroups, {}, (data) => {
    resolver(data.listGroups.items);
  });
};

export const getAllUsers = (resolver) => {
  return apiQuery(listDashUsers, {}, (data) => {
    resolver(data.listDashUsers.items);
  });
};

export const generateEducation = (inputs) => {
  return apiCreate(createEducation, {
    id: uuidv4(),
    ...inputs,
  });
};

export const generateGroup = (inputs) => {
  return apiCreate(createGroup, {
    id: uuidv4(),
    ...inputs,
  });
};

export const editUser = (user) => {
  return apiCreate(updateDashUser, user);
};

export const editGroup = (group) => {
  return apiCreate(updateGroup, group);
};

export const deleteUser = async (user) => {
  try {
    await adminQuery(
      {
        username: user.owner,
      },
      '/deleteUser'
    );
    await apiDelete(deleteDashUser, user.id);
  } catch (e) {
    console.log(e);
  }
};
