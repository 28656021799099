import React, { useState, useEffect, memo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import {
  LineChart,
  Customized,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Rectangle,
  Line,
  ResponsiveContainer,
  Brush,
} from 'recharts';
import { Select, Header, IconButton } from 'src/components';
import { Loading } from '../../helpers';
import {
  getFirstDateInData,
  getLastDateInData,
  getDaysBetweenDates,
  ChartContainer,
  NoData,
  TooltipContainer,
  getGroupedData,
  dateTickFormat,
} from './chartHelpers';

const groupHRDataByDay = (data, start, end) => {
  const newData = [];
  const dateMap = new Map();
  // group items by day
  for (const item of data) {
    const date = item.startDate.split('T')[0];
    if (!dateMap.has(date)) {
      dateMap.set(date, []);
    }
    dateMap.get(date).push(item.value);
  }
  // Loop through each day from start to end
  const currentDate = new Date(start);
  while (currentDate <= end) {
    const currentDay = currentDate.toISOString().split('T')[0]; // Get current day as ISO string
    if (dateMap.has(currentDay)) {
      const values = dateMap.get(currentDay);
      const low = Math.round(Math.min(...values));
      const high = Math.round(Math.max(...values));
      const sum = values.reduce((a, b) => parseInt(a) + parseInt(b), 0);
      const avg = Math.round(sum / values.length);
      newData.push({ date: new Date(currentDay).getTime(), low, high, avg });
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return newData;
};

const groupHRDataByHours = (data, start, end, numHours) => {
  const newData = [];
  let currentDateTime = new Date(start).getTime();
  const intervalMilliseconds = numHours * 60 * 60 * 1000;
  while (currentDateTime <= end.getTime()) {
    const intervalStart = currentDateTime;
    const intervalEnd = currentDateTime + intervalMilliseconds;
    let low = Infinity;
    let high = -Infinity;
    let sum = 0;
    let count = 0;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const itemDateTime = new Date(item.startDate).getTime();
      if (itemDateTime >= intervalStart && itemDateTime < intervalEnd) {
        const value = parseInt(item.value);
        low = Math.min(low, value);
        high = Math.max(high, value);
        sum += value;
        count++;
      }
    }
    if (count > 0) {
      const avg = Math.round(sum / count);
      newData.push({ date: currentDateTime, low, high, avg });
    }
    currentDateTime += intervalMilliseconds;
  }
  return newData;
};

const granularityOptions = {
  '3 Hours': (data, start, end) => groupHRDataByHours(data, start, end, 3),
  '6 Hours': (data, start, end) => groupHRDataByHours(data, start, end, 6),
  '12 Hours': (data, start, end) => groupHRDataByHours(data, start, end, 12),
  '24 Hours': (data, start, end) => groupHRDataByDay(data, start, end),
};

const HRTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const date = new Date(label);
    const dateLabel = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${String(
      date.getHours()
    ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    return (
      <TooltipContainer>
        <Typography variant="instructions">{dateLabel}</Typography>
        <Typography variant="paragraph">{`Range: ${payload[1].value}-${payload[0].value} bpm`}</Typography>
        <Typography variant="paragraph">{`Average: ${payload[2].value} bpm`}</Typography>
      </TooltipContainer>
    );
  }
  return null;
};

export const HeartRateChart = ({ data }) => {
  const theme = useTheme();

  const defaults = getGroupedData(data, granularityOptions);

  const [granularity, setGranularity] = useState(defaults[0]);
  const [groupedData, setGroupedData] = useState(defaults[1]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const newData = getGroupedData(data, granularityOptions);
    setGranularity(newData[0]);
    setGroupedData(newData[1]);
  }, [data]);

  useEffect(() => {
    const newData = getGroupedData(data, granularityOptions, granularity);
    setGroupedData(newData[1]);
    setLoading(false);
  }, [granularity]);

  const CustomizedRectangle = (props) => {
    const { formattedGraphicalItems } = props;
    // get first and second series in chart
    const firstSeries = formattedGraphicalItems[0];
    const secondSeries = formattedGraphicalItems[1];

    const chartWidth = firstSeries?.props?.width / 3;
    const width = Math.max(Math.min(chartWidth / firstSeries?.props?.points.length, 10), 1);

    // render custom content using points from the graph
    return firstSeries?.props?.points.map((firstSeriesPoint, index) => {
      const secondSeriesPoint = secondSeries?.props?.points[index];
      const yDifference = firstSeriesPoint.y - secondSeriesPoint.y;

      return (
        <Rectangle
          key={firstSeriesPoint.payload.name}
          width={width}
          height={yDifference}
          x={secondSeriesPoint.x - width / 2}
          y={secondSeriesPoint.y}
          radius={5}
          fill={theme.palette.gold.main}
        />
      );
    });
  };

  return (
    <ChartContainer>
      <Header title="Heart Rate" level={3} style={{ marginBottom: '20px' }}>
        <Select
          id="granularity-select"
          label="Granularity"
          options={Object.keys(granularityOptions)}
          value={granularity}
          setValue={(val) => {
            setLoading(true);
            setTimeout(() => {
              setGranularity(val);
            }, 500);
          }}
          sx={{ minWidth: '200px', maxHeight: '40px' }}
          stackSX={{ marginTop: '-10px' }}
        />
      </Header>
      {loading ? (
        <Loading text={'Chart'} />
      ) : groupedData.length ? (
        <ResponsiveContainer width="95%" height="90%">
          <LineChart data={groupedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              domain={['auto', 'auto']}
              type="number"
              scale="time"
              tickFormatter={dateTickFormat}
              tickMargin={8}
            />
            <YAxis
              type="number"
              label={'bpm'}
              angle={-45}
              // textAnchor="end"
            />
            <Tooltip content={HRTooltip} />
            <Brush
              dataKey="date"
              height={30}
              stroke={theme.palette.green.main}
              tickFormatter={dateTickFormat}
            />
            <Line
              type="monotone"
              dataKey="high"
              stroke="rgb(0, 0, 0, 0)"
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="low"
              stroke="rgb(0, 0, 0, 0)"
              isAnimationActive={false}
            />
            <Customized component={CustomizedRectangle} />
            <Line
              type="monotone"
              dataKey="avg"
              stroke={theme.palette.green.main}
              strokeWidth={2}
              dot={{ stroke: theme.palette.green.main, strokeWidth: 5, r: 2 }}
              activeDot={{ stroke: theme.palette.green.main, strokeWidth: 5, r: 4 }}
              name="Average"
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <NoData />
      )}
    </ChartContainer>
  );
};
