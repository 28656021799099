import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { Typography, Avatar, Box } from '@mui/material';
import { LoginForm } from './LoginForm';
import { LoginSmallScreen } from './LoginSmallScreen';
import { useWindowSize } from 'src/hooks';

const PromoStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.green.main,
  width: '50%',
  height: '100dvh',
  textAlign: 'center',
  justifyContent: 'space-between',
}));

const TopImage = styled('img')(({ theme }) => ({
  marginRight: 'auto',
  marginLeft: '10px',
  height: '35dvh',
  opacity: 0.7,
  filter: 'grayscale(100%)',
}));

const BottomImage = styled('img')(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: '10px',
  height: '40dvh',
  filter: 'grayscale(100%)',
}));

const PromoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.offWhite.main,
  padding: '0 10%',
}));

const LoginStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.offWhite.main,
  width: '50%',
  height: '100dvh',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

export const Login = () => {
  const { width } = useWindowSize();

  return width < 700 ? (
    <LoginSmallScreen />
  ) : (
    <Stack direction="row">
      <LoginStack>
        <Avatar alt="Cactus" src="/logo512.png" sx={{ width: 75, height: 75 }} />
        <Typography variant="h1" sx={{ padding: '0px 10px' }}>
          Welcome to Nanbar
        </Typography>
        <LoginForm />
      </LoginStack>
      <PromoStack>
        <Box display="flex">
          <TopImage src="/clinician-patient-white.png" />
        </Box>
        <PromoText variant="promo" sx={{ width: '80%', alignSelf: 'center' }}>
          Empowering Clinical Decisions with Data-Driven Insights
        </PromoText>
        <Box display="flex">
          <BottomImage src="/clinicians-white.png" />
        </Box>
      </PromoStack>
    </Stack>
  );
};
