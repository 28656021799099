import React, { useState, useEffect } from 'react';
import { create } from 'zustand';
import { getInsightsForGroup, runInsights } from 'src/queries/insights';

export const useInsights = create((set, get) => {
  return {
    chartOptions: ['NetworkChart', 'CentralityChart'],
    selectedChart: 'NetworkChart',
    insightsData: null,
    getInsightsForGroup: (groupName) => {
      return getInsightsForGroup(groupName, (data) => {
        return set({ insightsData: data });
      });
    },
    runInsights: (group, users) => {
      return runInsights(group, users);
    },
    setSelectedChart: (newChart) => {
      return set({ selectedChart: newChart });
    },
  };
});
