import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Button as MuiButton } from '@mui/material';
import { LoadingSpinner } from 'src/components';

const StyledMuiButton = styled(MuiButton)({
  '&.MuiButtonBase-root': {
    borderRadius: '10px',
    textTransform: 'none',
  },
});

const GreenMuiButton = styled(StyledMuiButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    height: '50px',
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.hover,
    },
  },
}));

const GoldMuiButton = styled(StyledMuiButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    height: '50px',
    backgroundColor: theme.palette.gold.main,
    '&:hover': {
      backgroundColor: theme.palette.gold.hover,
    },
  },
}));

const DisabledButton = styled(StyledMuiButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    height: '50px',
    backgroundColor: theme.palette.disabled.main,
  },
}));

export const Button = ({ variant, size, disabled, loading, ...props }) => {
  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
        <LoadingSpinner />
      </div>
    );
  }
  if (disabled) {
    return <DisabledButton variant="contained" disabled={disabled} {...props} />;
  }
  if (variant === 'primary') {
    return <GreenMuiButton variant="contained" {...props} />;
  }
  return <GoldMuiButton variant="contained" {...props} />;
};

Button.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
};

Button.defaultProps = {
  variant: 'primary',
  size: 'lg',
};
